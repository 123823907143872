import React, { useEffect } from 'react';
import {
    Box,
    Text,
    Flex,
    Stat,
    StatLabel,
    StatNumber,
    useColorModeValue,
    useToast,
    Icon,
} from '@chakra-ui/react';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { ArrowDownIcon, ArrowUpIcon } from '@chakra-ui/icons';

interface StockComponentProps {
    currentStock: number;
    metalUsedThisWeek: number;
    metalArrivingThisWeek: number;
}

const StockComponent: React.FC<StockComponentProps> = ({ currentStock, metalUsedThisWeek, metalArrivingThisWeek }) => {
    const maxStock = 30000;
    const percentage = (currentStock / maxStock) * 100;

    const toast = useToast();
    const lowStockThreshold = 5000; // Seuil pour le stock bas

    useEffect(() => {
        if (currentStock < lowStockThreshold) {
            toast({
                title: "Stock bas",
                description: "Le stock est très bas, pensez à passer une commande.",
                status: currentStock < lowStockThreshold / 2 ? "error" : "warning",
                duration: 5000,
                isClosable: true,
            });
        }
    }, [currentStock, lowStockThreshold, toast]);

    const getColor = (percentage: number) => {
        if (percentage <= 25) {
            return '#ff0000';
        } else if (percentage <= 70) {
            return '#ffa500';
        } else {
            return '#008000';
        }
    };

    const getBorderColor = (currentStock: number) => {
        if (currentStock < lowStockThreshold / 2) {
            return 'red.500';
        } else if (currentStock < lowStockThreshold) {
            return 'orange.500';
        } else {
            return 'white';
        }
    };

    const textColor = useColorModeValue('gray.800', 'white');
    const borderColor = getBorderColor(currentStock);

    return (
        <Box p={4} maxW="600px" mx="auto" textAlign="center" borderWidth={2} borderColor={borderColor} boxShadow="lg" borderRadius="lg">
            <Box mb={4}>
                <CircularProgressbarWithChildren
                    value={percentage}
                    strokeWidth={12}
                    styles={buildStyles({
                        rotation: 0.75,
                        strokeLinecap: 'round',
                        pathTransitionDuration: 0.5,
                        pathColor: getColor(percentage),
                        trailColor: '#e0e0e0',
                        pathTransition: 'none',
                    })}
                    circleRatio={0.5}
                >
                    <Text fontSize="2xl" fontWeight="bold" color={textColor} textShadow="1px 1px 2px rgba(0, 0, 0, 0.2)">
                        {`${currentStock} KG`}
                    </Text>
                    <Text fontSize="sm" color="gray.600" textShadow="1px 1px 2px rgba(0, 0, 0, 0.1)">
                        Stock actuel
                    </Text>
                </CircularProgressbarWithChildren>
            </Box>
            <Flex justify="space-around">
                <Stat>
                    <Flex direction="column" align="center">
                        <StatLabel>Métal utilisé cette semaine</StatLabel>
                        <Icon as={ArrowDownIcon} color="red.500" w={8} h={8} mt={2} />
                    </Flex>
                    <StatNumber>{metalUsedThisWeek} KG</StatNumber>
                </Stat>
                <Stat>
                    <Flex direction="column" align="center">
                        <StatLabel>Métal arrivant cette semaine</StatLabel>
                        <Icon as={ArrowUpIcon} color="green.500" w={8} h={8} mt={2} />
                    </Flex>
                    <StatNumber>{metalArrivingThisWeek} KG</StatNumber>
                </Stat>
            </Flex>
        </Box>
    );
};

export default StockComponent;
