import React, {useEffect, useState} from "react";
import {Box, Progress, Tag, TagLabel, Td, Text, Tr, useBreakpointValue, useColorModeValue} from "@chakra-ui/react";
import {fetchProgress} from "../../../common/progress";
import {ProjetsSteps} from "../projectDetails/ProgressBarComponent";
import {IProject} from "../ProjectDetailsComponent";
import {getStatusColor} from "../../../common/status";
import {useTranslation} from "react-i18next";

interface ProjectSummaryDetailsProps {
    project: any;
    idProject: string;
    handleProjectClick: (project: any) => void;
}

function ProjectSummaryDetailsComponent(props: ProjectSummaryDetailsProps) {
    const [progress, setProgress] = useState(0);

    const calculateStepIndex = () => Math.min(Math.floor(progress / (100 / ProjetsSteps.length)), ProjetsSteps.length - 1);

    const getColorScheme = (index: number) => ["red", "orange", "yellow", "green"][index] || "gray";
    const isSmallScreen = useBreakpointValue({base: true, md: false});
    const fontSize = useBreakpointValue({base: 'xs', md: 'sm'});
    const paddingSize = useBreakpointValue({base: '1', md: '2'});
    const {t} = useTranslation();

    useEffect(() => {
        const updateProgress = async () => {
            const response = await fetchProgress(props.idProject);
            setProgress(response);
        };

        updateProgress();
    }, [props.idProject]);

    return (
        <Tr key={props.idProject} _hover={{bg: useColorModeValue('gray.100', 'gray.700')}} onClick={() => props.handleProjectClick(props.project)}>
            <Td fontSize={fontSize} px={paddingSize}>{props.project.reference}</Td>
            <Td px={paddingSize}>
                <Tag size={isSmallScreen ? "sm" : "md"} colorScheme={getStatusColor(props.project.status)}
                     borderRadius="full">
                    <TagLabel fontSize={fontSize}>{t(props.project.status)}</TagLabel>
                </Tag>
            </Td>
            <Td fontSize={fontSize} px={paddingSize}>{props.project.date.end}</Td>
            <Td px={paddingSize}>
                <Box minW="75px">
                    <Progress value={progress} size="xs" colorScheme={getColorScheme(calculateStepIndex())}
                              borderRadius="full" hasStripe isAnimated/>
                </Box>
            </Td>
        </Tr>
    )
}

export default ProjectSummaryDetailsComponent;