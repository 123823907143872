import React from "react";
import {Box, Button, Flex, useColorModeValue} from "@chakra-ui/react";
import {getButtonStatusValidator} from "../../../../common/status";
import axios from "axios";
import {getAuthorizedHeader} from "../../../../common/auth";
import {DownloadIcon} from "@chakra-ui/icons";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faPencil} from "@fortawesome/free-solid-svg-icons";

interface TaskValidatorProps {
    idProject: string;
    task: string;
    status: string
    callback: () => void;
}

function TaskValidatorComponent(props: TaskValidatorProps) {
    const {t} = useTranslation();

    function getButtonColor(status: string) {
        if (status === 'start') {
            return 'orange';
        } else if (status === 'end') {
            return 'green';
        }
        return 'yellow';
    }

    async function changeTaskStatus() {
        try {
            const response = await axios.put('/api/' + props.task + '/status/' + props.idProject, {}, {
                headers: getAuthorizedHeader(),
            });

            props.callback();
        } catch (error) {
            console.error('Error fetching Task:', error);
        }
    }

    const borderColor = getButtonColor(getButtonStatusValidator(props.status));
    const hoverBgColor = useColorModeValue(`${getButtonColor(getButtonStatusValidator(props.status))}.300`, `${getButtonColor(getButtonStatusValidator(props.status))}.600`);
    const placeholderColor = useColorModeValue('black', 'black');

    return (
        <Box onClick={(e) => e.stopPropagation()}>
            <Button
                leftIcon={<FontAwesomeIcon icon={faCheck}/>}
                size="sm"
                borderRadius="full"
                boxShadow="md"
                bg="transparent"
                rounded={"2xl"}
                border={`2px solid ${borderColor}`}
                _hover={{bg: 'rgba(255, 165, 0, 0.1)'}}
                _focus={{boxShadow: "none"}}
                _placeholder={{color: placeholderColor}}
                onClick={changeTaskStatus}
                p={4}
            >{getButtonStatusValidator(props.status)}</Button>
        </Box>
    )
}

export default TaskValidatorComponent;