import React, {useEffect, useState} from "react";
import {
    Box,
    Card,
    CardBody,
    Divider,
    Flex,
    Heading,
    Stack,
    StackDivider,
    Text,
    useBreakpointValue,
    useColorModeValue
} from "@chakra-ui/react";
import axios from "axios";
import {getAuthorizedHeader} from "../../../common/auth";
import DataDetails from "./DataDetailsComponent";
import {useTranslation} from "react-i18next";


interface ClientProps {
    idClient: string;
}

interface ClientData {
    name: string;
    email: string;
    phone: string;
}

function ClientComponent(props: ClientProps) {
    const isSmallScreen = useBreakpointValue({base: true, md: false});
    const {t} = useTranslation();
    const [client, setClient] = useState<ClientData | null>(null);
    const [isLoading, setIsLoading] = useState(true);

    const fetchClient = async (idClient: string): Promise<void> => {
        setIsLoading(true);
        try {
            const response = await axios.get('/api/client/' + idClient, {
                headers: getAuthorizedHeader(),
            });
            setClient(response.data);
        } catch (error) {
            console.error('Error fetching Clients:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchClient(props.idClient);
    }, [props.idClient]);

    if (isLoading) {
        return <div>{t("Loading")}</div>;
    }

    if (!client) {
        return <div>{t("NotData")}</div>;
    }

    return (
        <Card padding={5}
              bg={useColorModeValue('white', 'gray.600')}
              border-radius={4}
              rounded="md"
              boxShadow="md"
              marginTop={isSmallScreen ? 0 : 5}
              width={isSmallScreen ? "100%" : 'calc(50% + 10px)'}
              mx={0}
              mb={0}
              minHeight={"100%"}
        >
            <Heading alignItems="flex-start" fontSize='2xl' color="gray.300">{t("Client")}</Heading>
            <Divider color="gray.300" width="100%"/>
            <DataDetails header={t("Nom")} detail={client.name}/>
            <DataDetails header={t("Email")} detail={client.email}/>
            <DataDetails header={t("Phone")} detail={client.phone}/>
        </Card>
    );
}

export default ClientComponent;