import React, {useState, useRef} from 'react';
import axios from 'axios';
import {
  Box,
  Text,
  Spinner,
  Center,
  Card,
  Avatar,
  Flex,
  Grid,
  Divider,
  useColorModeValue,
  IconButton, Button, Input, Image, SimpleGrid,
} from '@chakra-ui/react';
import {MdRemoveRedEye} from 'react-icons/md';
import {getAuthorizedHeader} from '../../common/auth';
import {IProfile} from './Profile';
import {useTranslation} from 'react-i18next';

interface ProfileComponentProperties {
  user: IProfile;
}

interface UserDetailsProps {
  label: string;
  value: string;
}

function UserDetailsItem({label, value}: UserDetailsProps) {
  const {t} = useTranslation();
  return (
    <Flex alignItems="flex-start" flexDirection="column" mt="2">
      <Text color="gray.400" fontSize={{base: "xs", md: "sm", lg: "md"}} fontWeight="400" mb="1">
        {label}
      </Text>
      <Text color="secondaryGray.900" fontSize={{base: "sm", md: "xl", lg: "2xl"}} fontWeight="700">
        {value}
      </Text>
    </Flex>
  );
}

function UserDetails({user}: ProfileComponentProperties) {
  return (
    <Box>
      <UserDetailsItem label="Phone" value={user.phone}/>
      <UserDetailsItem label="Email" value={user.email}/>
      <UserDetailsItem label="Password" value={'*'.repeat(user.passwordLength)}/>
    </Box>
  );
}

function ProfileComponent(props: ProfileComponentProperties) {
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'gray.400';
  const user = props.user;
  const borderColor = useColorModeValue('white !important', '#111C44 !important');
  const bgColor = useColorModeValue('#B6D8F2', '#2D4B73');
  const profilePictureUrl = process.env.REACT_APP_API_URL as string;

  const handleUploadImage = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileInput = event.target;

    if (fileInput.files && fileInput.files.length > 0) {
      setLoading(true);

      const formData = new FormData();
      formData.append('image', fileInput.files[0]);
      try {
        await axios.post('/api/user/picture/upload', formData, {headers: getAuthorizedHeader()});
        window.location.reload();
      } catch (error) {
        alert('Erreur lors du téléchargement de l\'image :' + error);
      } finally {
        setLoading(false);
        fileInput.value = '';
      }
    }
  };

  if (!user) {
    return <Text>Impossible de récupérer le profil de l'utilisateur.</Text>;
  }

  return (
    <Box p={4}>
      <Flex align="center" flexDirection={"column"} bg={bgColor} p={10}
            rounded="md"
            boxShadow="md">
        <Box
          mx="auto"
          h="125px"
          w="125px"
          border="4px solid"
          borderColor={borderColor}
          borderRadius="full"
          overflow="hidden"
          position="relative"
          onClick={handleUploadImage}
        >
          {user.profilePicture ?
            <Image
              src={profilePictureUrl + '/api/user/picture/' + user.profilePicture}
              alt={'Profile Picture'}
              borderRadius="full"
              objectFit="cover"
              width="100%"
              height="100%"
            />
            :
            <Text
              fontSize="xl"
              fontWeight="bold"
              color={textColorPrimary}
              position="absolute"
              top="50%"
              left="50%"
              transform="translate(-50%, -50%)"
            >
              Add Profile Picture
            </Text>}
        </Box>
        <Text color={textColorPrimary} fontWeight="bold" fontSize={{base: "sm", md: "xl", lg: "2xl"}} mt="10px">
          {user.lastname} {user.firstname}
        </Text>
        <Text color={textColorSecondary} fontSize={{base: "xs", md: "sm", lg: "md"}}>
          {user.job}
        </Text>
        <Divider m="10px" w="50%"/>
        <UserDetails user={user}/>

        <Input
          type="file"
          ref={fileInputRef}
          style={{display: 'none'}}
          onChange={handleFileChange}
        />
      </Flex>
    </Box>
  );
}

export default ProfileComponent;
