import React, { useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Avatar,
    AvatarGroup,
    Text,
    IconButton,
    Flex,
    SimpleGrid,
    Input,
    Select,
    Grid,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    Box,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon, Divider,
} from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon, SettingsIcon } from '@chakra-ui/icons';
import ButtonComponent from "../../common/ButtonComponent";

interface Task {
    name: string;
    startDate: string;
    endDate: string;
    assignedTo: {
        type: 'person' | 'team';
        name: string;
        members?: string[];
    };
}

const employees = ['Jean Dupont', 'Claire Delacroix', 'Lucie', 'Julien', 'Marc', 'Alice Bernard'];

interface CreateTaskModalProps {
    isOpen: boolean;
    onClose: () => void;
    handleCreateTask: (newTask: Task) => void;
}

const CreateTaskModal: React.FC<CreateTaskModalProps> = ({ isOpen, onClose, handleCreateTask }) => {
    const [teams, setTeams] = useState([
        { name: 'Equipe 1', members: ['Jean Dupont', 'Lucie', 'Julien'] },
        { name: 'Equipe 2', members: ['Claire Delacroix', 'Marc', 'Alice Bernard'] },
        { name: 'Equipe 3', members: ['Jean Dupont', 'Marc', 'Alice Bernard'] },
        { name: 'Equipe 4', members: ['Lucie', 'Julien', 'Claire Delacroix'] },
    ]);

    const [newTask, setNewTask] = useState<Task>({
        name: '',
        startDate: '',
        endDate: '',
        assignedTo: {
            type: 'person',
            name: '',
            members: [],
        },
    });
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentTeamIndex, setCurrentTeamIndex] = useState(0);
    const [manageMode, setManageMode] = useState(false);

    const nextEmployee = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % employees.length);
    };

    const prevEmployee = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + employees.length) % employees.length);
    };

    const nextTeam = () => {
        setCurrentTeamIndex((prevIndex) => (prevIndex + 1) % teams.length);
    };

    const prevTeam = () => {
        setCurrentTeamIndex((prevIndex) => (prevIndex - 1 + teams.length) % teams.length);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setNewTask({ ...newTask, [name]: value });
    };

    const handleAssignedToChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const { value } = e.target;
        setNewTask({
            ...newTask,
            assignedTo: { ...newTask.assignedTo, type: value as 'person' | 'team' },
        });
    };

    const toggleManageMode = () => {
        setManageMode(!manageMode);
    };

    const handleCreate = () => {
        handleCreateTask(newTask);
        onClose();
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Créer une nouvelle tâche</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Box width="100%">
                        <SimpleGrid columns={2} spacing={9} mb={6}>
                            <Input
                                mt={7}
                                placeholder="Nom"
                                name="name"
                                value={newTask.name}
                                onChange={handleInputChange}
                                variant="flushed"
                                mr={4}
                            />
                            <Box>
                                <Text fontSize="xs" color="gray.500" mb={2}>
                                    Début
                                </Text>
                                <Input
                                    placeholder="Date de début"
                                    name="startDate"
                                    type="date"
                                    value={newTask.startDate}
                                    onChange={handleInputChange}
                                    variant="flushed"
                                />
                            </Box>
                            <Select
                                mt={7}
                                variant="flushed"
                                name="assignedToType"
                                mr={4}
                                onChange={handleAssignedToChange}
                                value={newTask.assignedTo.type}
                            >
                                <option value="person">Personne</option>
                                <option value="team">Équipe</option>
                            </Select>

                            <Box>
                                <Text fontSize="xs" color="gray.500" mb={2}>
                                    Fin
                                </Text>
                                <Input
                                    placeholder="Date de fin"
                                    name="endDate"
                                    type="date"
                                    value={newTask.endDate}
                                    onChange={handleInputChange}
                                    variant="flushed"
                                />
                            </Box>
                        </SimpleGrid>
                        {newTask.assignedTo.type === 'team' && !manageMode ? (
                            <>
                                <Grid templateColumns="1fr auto 1fr" alignItems="center" my={4} width="100%">
                                    <Flex justifyContent="flex-end" pr={2}>
                                        <IconButton
                                            icon={<ChevronLeftIcon />}
                                            onClick={prevTeam}
                                            aria-label="Previous Team"
                                            background="transparent"
                                            _hover={{ background: 'gray.100' }}
                                            boxShadow="md"
                                        />
                                    </Flex>
                                    <Flex justifyContent="center" alignItems="center" mx="auto">
                                        <Avatar
                                            name={teams[(currentTeamIndex - 1 + teams.length) % teams.length].name}
                                            size="lg"
                                            mx={2}
                                            opacity={0.7}
                                            transition="transform 0.3s ease, opacity 0.3s ease"
                                            _hover={{ transform: 'scale(1.05)', opacity: 1 }}
                                            onClick={() =>
                                                setNewTask({
                                                    ...newTask,
                                                    assignedTo: {
                                                        type: 'team',
                                                        name: teams[(currentTeamIndex - 1 + teams.length) % teams.length].name,
                                                        members: teams[(currentTeamIndex - 1 + teams.length) % teams.length].members,
                                                    },
                                                })
                                            }
                                        />
                                        <Popover trigger="hover" placement="top">
                                            <PopoverTrigger>
                                                <Avatar
                                                    name={teams[currentTeamIndex].name}
                                                    size="2xl"
                                                    mx={4}
                                                    boxShadow="lg"
                                                    transition="transform 0.3s ease"
                                                    _hover={{ transform: 'scale(1.1)' }}
                                                    border={newTask.assignedTo.name === teams[currentTeamIndex].name ? '3px solid black' : 'none'}
                                                    onClick={() =>
                                                        setNewTask({
                                                            ...newTask,
                                                            assignedTo: {
                                                                type: 'team',
                                                                name: teams[currentTeamIndex].name,
                                                                members: teams[currentTeamIndex].members,
                                                            },
                                                        })
                                                    }
                                                />
                                            </PopoverTrigger>
                                            <PopoverContent>
                                                <PopoverBody>
                                                    <Text fontWeight="bold">Membres de l'équipe :</Text>
                                                    <AvatarGroup size="sm" max={4} mt={2}>
                                                        {teams[currentTeamIndex].members.map((member, index) => (
                                                            <Avatar key={index} name={member} />
                                                        ))}
                                                    </AvatarGroup>
                                                </PopoverBody>
                                            </PopoverContent>
                                        </Popover>
                                        <Avatar
                                            name={teams[(currentTeamIndex + 1) % teams.length].name}
                                            size="lg"
                                            mx={2}
                                            opacity={0.7}
                                            transition="transform 0.3s ease, opacity 0.3s ease"
                                            _hover={{ transform: 'scale(1.05)', opacity: 1 }}
                                            onClick={() =>
                                                setNewTask({
                                                    ...newTask,
                                                    assignedTo: {
                                                        type: 'team',
                                                        name: teams[(currentTeamIndex + 1) % teams.length].name,
                                                        members: teams[(currentTeamIndex + 1) % teams.length].members,
                                                    },
                                                })
                                            }
                                        />
                                    </Flex>
                                    <Flex justifyContent="flex-start" pl={2}>
                                        <IconButton
                                            icon={<ChevronRightIcon />}
                                            onClick={nextTeam}
                                            aria-label="Next Team"
                                            background="transparent"
                                            _hover={{ background: 'gray.100' }}
                                            boxShadow="md"
                                        />
                                    </Flex>
                                </Grid>
                                <Button size="xs" onClick={toggleManageMode} ml="auto" p={2}  _hover={{ background: 'none' }} variant="ghost">
                                    <IconButton
                                        _hover={{ background: 'none' }}
                                        aria-label="Manage"
                                        icon={<SettingsIcon />}
                                        variant="ghost"
                                    />
                                </Button>

                            </>
                        ) : manageMode && newTask.assignedTo.type === 'team' ? (
                            <>
                                <Box width="100%" maxHeight="300px" overflowY="auto" mx="auto">
                                    <Accordion allowToggle width="100%">
                                        {teams.map((team, index) => (
                                            <AccordionItem
                                                key={index}
                                                border="1px solid"
                                                borderColor="gray.200"
                                                borderRadius="md"
                                                mt={3}
                                                width="100%"
                                            >
                                                <AccordionButton _expanded={{ bg: 'blue.50', color: 'blue.600' }}>
                                                    <Box flex="1" textAlign="left" fontWeight="bold">
                                                        {team.name}
                                                    </Box>
                                                    <AccordionIcon />
                                                    <Button
                                                        size="xs"
                                                        colorScheme="red"
                                                        ml={2}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            const updatedTeams = teams.filter((_, i) => i !== index);
                                                            setTeams(updatedTeams);
                                                        }}
                                                    >
                                                        Supprimer
                                                    </Button>
                                                </AccordionButton>
                                                <AccordionPanel pb={4} bg="gray.50" width="100%">
                                                    <Text fontSize="lg" fontWeight="bold" mb={4}>
                                                        Membres de l'Équipe
                                                    </Text>
                                                    {employees.map((employee, employeeIndex) => {
                                                        const isMember = team.members.includes(employee);
                                                        return (
                                                            <Box key={employeeIndex}>
                                                                <Flex
                                                                    align="center"
                                                                    justify="space-between"
                                                                    py={3}
                                                                    px={4}
                                                                    bg={isMember ? 'blue.50' : 'white'}
                                                                    borderRadius="md"
                                                                    boxShadow="sm"
                                                                    _hover={{ bg: 'gray.100' }}
                                                                >
                                                                    <Text fontSize="md" fontWeight={isMember ? 'semibold' : 'normal'}>
                                                                        {employee}
                                                                    </Text>
                                                                    <Button
                                                                        size="sm"
                                                                        colorScheme={isMember ? 'red' : 'green'}
                                                                        onClick={() => {
                                                                            const newMembers = isMember
                                                                                ? team.members.filter(m => m !== employee)
                                                                                : [...team.members, employee];
                                                                            teams[index].members = newMembers;
                                                                            setNewTask({
                                                                                ...newTask,
                                                                                assignedTo: { ...newTask.assignedTo, members: newMembers },
                                                                            });
                                                                        }}
                                                                        _hover={{ transform: 'scale(1.05)', transition: '0.2s' }}
                                                                    >
                                                                        {isMember ? 'Supprimer' : 'Ajouter'}
                                                                    </Button>
                                                                </Flex>
                                                                {employeeIndex < employees.length - 1 && <Divider my={2} />}
                                                            </Box>
                                                        );
                                                    })}
                                                </AccordionPanel>
                                            </AccordionItem>
                                        ))}
                                    </Accordion>
                                </Box>

                                <Flex justifyContent="flex-end" mt={6}>
                                    <Button size="sm" onClick={toggleManageMode} colorScheme="blue" variant="outline" width="auto">
                                        Back to Carousel
                                    </Button>
                                </Flex>
                            </>
                        ) : (
                            <Grid templateColumns="1fr auto 1fr" alignItems="center" my={4} width="100%">
                                <Flex justifyContent="flex-end" pr={2}>
                                    <IconButton
                                        icon={<ChevronLeftIcon />}
                                        onClick={prevEmployee}
                                        aria-label="Previous"
                                        background="transparent"
                                        _hover={{ background: 'gray.100' }}
                                        boxShadow="md"
                                    />
                                </Flex>

                                <Flex justifyContent="center" alignItems="center" mx="auto">
                                    <Avatar
                                        name={employees[(currentIndex - 1 + employees.length) % employees.length]}
                                        size="lg"
                                        mx={2}
                                        opacity={0.7}
                                        transition="transform 0.3s ease, opacity 0.3s ease"
                                        _hover={{ transform: 'scale(1.05)', opacity: 1 }}
                                    />
                                    <Avatar
                                        name={employees[currentIndex]}
                                        size="2xl"
                                        mx={4}
                                        boxShadow="lg"
                                        transition="transform 0.3s ease"
                                        _hover={{ transform: 'scale(1.1)' }}
                                        border={newTask.assignedTo.name === employees[currentIndex] ? '3px solid black' : 'none'}
                                        onClick={() =>
                                            setNewTask({ ...newTask, assignedTo: { type: 'person', name: employees[currentIndex] } })
                                        }
                                    />
                                    <Avatar
                                        name={employees[(currentIndex + 1) % employees.length]}
                                        size="lg"
                                        mx={2}
                                        opacity={0.7}
                                        transition="transform 0.3s ease, opacity 0.3s ease"
                                        _hover={{ transform: 'scale(1.05)', opacity: 1 }}
                                    />
                                </Flex>

                                <Flex justifyContent="flex-start" pl={2}>
                                    <IconButton
                                        icon={<ChevronRightIcon />}
                                        onClick={nextEmployee}
                                        aria-label="Next"
                                        background="transparent"
                                        _hover={{ background: 'gray.100' }}
                                        boxShadow="md"
                                    />
                                </Flex>
                            </Grid>
                        )}
                    </Box>
                </ModalBody>
                <ModalFooter>
                    <ButtonComponent
                        text="New"
                        callback={() => (true)}
                    />
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default CreateTaskModal;
