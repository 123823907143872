import React, { useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/fr';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Box, Flex, Heading, useBreakpointValue, useColorModeValue } from '@chakra-ui/react';
import { IEvent, parseEventDate } from "../../screens/CalendarScreen";
import { IAppointment } from "./CalendarComponent";

moment.locale('fr');

const localizer = momentLocalizer(moment);

type WeekCalendarProps = {
    events: IAppointment[];
};

const eventPropGetter = (event: IEvent) => {
    let backgroundColor, textColor;
    switch (event.type.toLowerCase()) {
        case 'project':
            backgroundColor = "#3182CE"; // Blue
            textColor = "#FFFFFF";
            break;
        case 'banque':
            backgroundColor = "#D69E2E"; // Yellow
            textColor = "#FFFFFF";
            break;
        case 'reunion':
            backgroundColor = "#38A169"; // Green
            textColor = "#FFFFFF";
            break;
        case 'rendez-vous':
            backgroundColor = "#9F7AEA"; // Purple
            textColor = "#FFFFFF";
            break;
        default:
            backgroundColor = "#CBD5E0"; // Light Gray for default
            textColor = "#000000";
            break;
    }
    return {
        style: {
            backgroundColor,
            color: textColor,
            borderRadius: '0px',
            border: 'none',
            padding: '5px 10px',
            fontSize: '0.875rem',
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            height: '100%',
        },
    };
};

const WeekCalendar: React.FC<WeekCalendarProps> = ({ events }) => {
    const isSmallScreen = useBreakpointValue({ base: true, md: false });
    const [selectedEvent, setSelectedEvent] = useState<IEvent | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalBorderColor, setModalBorderColor] = useState<string>('#CBD5E0');

    const handleSelectEvent = (event: IEvent) => {
        setSelectedEvent(event);
        setIsModalOpen(true);
        setModalBorderColor(eventPropGetter(event).style.backgroundColor);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedEvent(null);
    };

    return (
        <Box
            bg={useColorModeValue("gray.200", "gray.50")}
            rounded="md"
            boxShadow="md"
            textAlign="center"
            width="100%"
            p={isSmallScreen ? 3 : 6}
            m={isSmallScreen ? 3 : 5}
            mx="auto"
        >
            <Box
                bg="white"
                p={isSmallScreen ? 2 : 4}
                rounded="md"
                boxShadow="lg"
                overflowX="auto"
                width="100%"
            >
                <Box minWidth={isSmallScreen ? '100%' : '1200px'}>
                    <Calendar
                        localizer={localizer}
                        events={events.map((event) => ({
                            ...event,
                            title: event.name,
                            start: parseEventDate(event.date, event.timeStart),
                            end: parseEventDate(event.date, event.timeEnd),
                        }))}
                        startAccessor="start"
                        endAccessor="end"
                        views={['week']}
                        defaultView="week"
                        style={{
                            height: isSmallScreen ? 500 : 800, // Adjust height based on screen size
                            width: '100%', // Make the calendar take full width
                        }}
                        eventPropGetter={eventPropGetter}
                        onSelectEvent={handleSelectEvent}
                        min={new Date(1970, 1, 1, 8, 0, 0)}  // Start time for workday (8 AM)
                        max={new Date(1970, 1, 1, 21, 0, 0)}  // End time for workday (9 PM)
                        step={20}  // Interval in minutes
                        timeslots={3}  // Number of time slots per step
                        popup
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default WeekCalendar;
