import React, { useState } from 'react';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableCaption,
    Box,
    Text,
    Divider,
    useColorModeValue,
    Button,
} from '@chakra-ui/react';
import { format, isBefore, differenceInDays } from 'date-fns';

interface Project {
    id: number;
    name: string;
    company: string;
    buildingType: string;
    deliveryDate: string;
    steelQuantity: number;
    status: string;
}

const initialProjects: Project[] = [
    { id: 1, name: 'Projet 1', company: 'Entreprise A', buildingType: 'Industriel', deliveryDate: '2023-07-01', steelQuantity: 1000, status: 'À faire' },
    { id: 2, name: 'Projet 2', company: 'Entreprise B', buildingType: 'Commercial', deliveryDate: '2023-08-01', steelQuantity: 1500, status: 'À faire' },
    { id: 3, name: 'Projet 3', company: 'Entreprise C', buildingType: 'Résidentiel', deliveryDate: '2024-01-01', steelQuantity: 1200, status: 'À faire' },
    { id: 4, name: 'Projet 4', company: 'Entreprise D', buildingType: 'Industriel', deliveryDate: '2024-02-15', steelQuantity: 900, status: 'À faire' },
    { id: 5, name: 'Projet 5', company: 'Entreprise E', buildingType: 'Commercial', deliveryDate: '2024-03-30', steelQuantity: 1100, status: 'À faire' },
    { id: 6, name: 'Projet 6', company: 'Entreprise F', buildingType: 'Industriel', deliveryDate: '2024-04-20', steelQuantity: 1300, status: 'À faire' },
    { id: 7, name: 'Projet 7', company: 'Entreprise G', buildingType: 'Résidentiel', deliveryDate: '2024-05-25', steelQuantity: 1400, status: 'À faire' },
    { id: 8, name: 'Projet 8', company: 'Entreprise H', buildingType: 'Commercial', deliveryDate: '2024-06-15', steelQuantity: 1600, status: 'À faire' },
    { id: 9, name: 'Projet 9', company: 'Entreprise I', buildingType: 'Industriel', deliveryDate: '2024-07-10', steelQuantity: 1700, status: 'À faire' },
    { id: 10, name: 'Projet 10', company: 'Entreprise J', buildingType: 'Résidentiel', deliveryDate: '2024-08-05', steelQuantity: 1800, status: 'À faire' },
    { id: 11, name: 'Projet 11', company: 'Entreprise K', buildingType: 'Commercial', deliveryDate: '2024-09-01', steelQuantity: 1900, status: 'À prévoir' },
    { id: 12, name: 'Projet 12', company: 'Entreprise L', buildingType: 'Industriel', deliveryDate: '2024-10-10', steelQuantity: 2000, status: 'À prévoir' },
    { id: 13, name: 'Projet 13', company: 'Entreprise M', buildingType: 'Résidentiel', deliveryDate: '2024-11-05', steelQuantity: 2100, status: 'À prévoir' },
    { id: 14, name: 'Projet 14', company: 'Entreprise N', buildingType: 'Commercial', deliveryDate: '2024-12-15', steelQuantity: 2200, status: 'À prévoir' },
    { id: 15, name: 'Projet 15', company: 'Entreprise O', buildingType: 'Industriel', deliveryDate: '2025-01-20', steelQuantity: 2300, status: 'À prévoir' },
    { id: 16, name: 'Projet 16', company: 'Entreprise P', buildingType: 'Résidentiel', deliveryDate: '2025-02-25', steelQuantity: 2400, status: 'À prévoir' },
    { id: 17, name: 'Projet 17', company: 'Entreprise Q', buildingType: 'Commercial', deliveryDate: '2025-03-30', steelQuantity: 2500, status: 'À prévoir' },
    { id: 18, name: 'Projet 18', company: 'Entreprise R', buildingType: 'Industriel', deliveryDate: '2025-04-15', steelQuantity: 2600, status: 'À prévoir' },
    { id: 19, name: 'Projet 19', company: 'Entreprise S', buildingType: 'Résidentiel', deliveryDate: '2025-05-10', steelQuantity: 2700, status: 'À prévoir' },
    { id: 20, name: 'Projet 20', company: 'Entreprise T', buildingType: 'Commercial', deliveryDate: '2025-06-05', steelQuantity: 2800, status: 'À prévoir' },
    { id: 21, name: 'Projet 21', company: 'Entreprise U', buildingType: 'Industriel', deliveryDate: '2025-07-01', steelQuantity: 2900, status: 'À venir' },
    { id: 22, name: 'Projet 22', company: 'Entreprise V', buildingType: 'Résidentiel', deliveryDate: '2025-08-01', steelQuantity: 3000, status: 'À venir' },
    { id: 23, name: 'Projet 23', company: 'Entreprise W', buildingType: 'Commercial', deliveryDate: '2025-09-01', steelQuantity: 3100, status: 'À venir' },
    { id: 24, name: 'Projet 24', company: 'Entreprise X', buildingType: 'Industriel', deliveryDate: '2025-10-01', steelQuantity: 3200, status: 'À venir' },
    { id: 25, name: 'Projet 25', company: 'Entreprise Y', buildingType: 'Résidentiel', deliveryDate: '2025-11-01', steelQuantity: 3300, status: 'À venir' },
    { id: 26, name: 'Projet 26', company: 'Entreprise Z', buildingType: 'Commercial', deliveryDate: '2025-12-01', steelQuantity: 3400, status: 'À venir' },
    { id: 27, name: 'Projet 27', company: 'Entreprise AA', buildingType: 'Industriel', deliveryDate: '2026-01-01', steelQuantity: 3500, status: 'À venir' },
    { id: 28, name: 'Projet 28', company: 'Entreprise AB', buildingType: 'Résidentiel', deliveryDate: '2026-02-01', steelQuantity: 3600, status: 'À venir' },
    { id: 29, name: 'Projet 29', company: 'Entreprise AC', buildingType: 'Commercial', deliveryDate: '2026-03-01', steelQuantity: 3700, status: 'À venir' },
    { id: 30, name: 'Projet 30', company: 'Entreprise AD', buildingType: 'Industriel', deliveryDate: '2026-04-01', steelQuantity: 3800, status: 'À venir' }
];

const UsineComponent: React.FC = () => {
    const [projectList, setProjectList] = useState<Project[]>(initialProjects);

    const handleValidateProject = (id: number) => {
        setProjectList(prevProjects =>
            prevProjects.map(project =>
                project.id === id ? { ...project, status: 'Terminé' } : project
            )
        );
    };

    const sortedProjects = projectList.sort((a, b) => a.id - b.id);
    const sections = {
        'À faire': sortedProjects.filter(project => project.status === 'À faire'),
        'À prévoir': sortedProjects.filter(project => project.status === 'À prévoir'),
        'À venir': sortedProjects.filter(project => project.status === 'À venir'),
        'Terminé': sortedProjects.filter(project => project.status === 'Terminé'),
    };

    const sizeTitre = '2xl';
    const sizeText = 'xl';
    const textColor = 'gray.800';
    const secondaryTextColor = 'gray.600';
    const dividerColor = 'gray.200';

    const bgColorFaire = useColorModeValue('orange.100', 'orange.700');
    const bgColorPrevoir = useColorModeValue('yellow.100', 'yellow.700');
    const bgColorVenir = useColorModeValue('green.100', 'green.700');
    const bgColorRetard = useColorModeValue('red.100', 'red.700');

    return (
        <Box width="100%" maxHeight="900px" overflowY="auto" boxShadow="lg" borderRadius="lg">
            {Object.entries(sections).map(([status, projects]) => (
                <Box
                    key={status}
                    mb={8}
                    bgGradient="linear(to-t, gray.50, white)"
                    borderRadius="md"
                    p={4}
                >
                    <Text
                        transition="transform 0.2s"
                        _hover={{ transform: 'scale(1.03)' }}
                        fontSize={{ base: sizeTitre, md: '4xl' }}
                        color={textColor}
                        textAlign="left"
                        fontWeight="semibold"
                        mb={4}
                        borderLeft="4px solid"
                        borderColor={dividerColor}
                        pl={3}
                    >
                        {status}
                    </Text>
                    <Divider borderColor={dividerColor} />
                    <Box maxHeight="400px" overflowY="auto">
                        <Table variant="simple" mt={6}>
                            <Thead>
                                <Tr>
                                    <Th>Nom / Entreprise</Th>
                                    <Th>Type de bâtiment</Th>
                                    <Th>Date de livraison</Th>
                                    <Th>Quantité d'acier (KG)</Th>
                                    {status === 'À faire' && <Th>Action</Th>}
                                </Tr>
                            </Thead>
                            <Tbody>
                                {projects.map((project) => {
                                    const deliveryDate = new Date(project.deliveryDate);
                                    const isLate = isBefore(deliveryDate, new Date());
                                    const daysRemaining = differenceInDays(deliveryDate, new Date());
                                    const isDueSoon = daysRemaining < 2 && project.status === 'À faire';
                                    const bgColor = isLate || isDueSoon ? bgColorRetard :
                                        status === 'À faire' ? bgColorFaire :
                                            status === 'À prévoir' ? bgColorPrevoir :
                                                bgColorVenir;
                                    const textColor = isLate || isDueSoon ? 'red.600' : 'inherit';

                                    return (
                                        <Tr key={project.id} bg={bgColor} border={isDueSoon ? '2px solid red' : 'none'}>
                                            <Td color={textColor}>{`${project.name} / ${project.company}`}</Td>
                                            <Td color={textColor}>{project.buildingType}</Td>
                                            <Td color={textColor}>{format(deliveryDate, 'yyyy-MM-dd')}</Td>
                                            <Td color={textColor}>{project.steelQuantity}</Td>
                                            {status === 'À faire' && (
                                                <Td>
                                                    <Button
                                                        colorScheme="green"
                                                        onClick={() => handleValidateProject(project.id)}
                                                    >
                                                        Valider
                                                    </Button>
                                                </Td>
                                            )}
                                        </Tr>
                                    );
                                })}
                            </Tbody>
                        </Table>
                    </Box>
                </Box>
            ))}
        </Box>
    );
};

export default UsineComponent;
