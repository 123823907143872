import React from "react";
import {Box, Text, useColorModeValue} from "@chakra-ui/react";
import CopyButtonComponent from "../../common/CopyButtonComponent";

interface DataDetailsProps {
    header: string;
    detail: any;
}

function DataDetails(props: DataDetailsProps) {


    return (
        <Box marginTop={3}
             bg={useColorModeValue('white', 'gray.600')}
        >
            <Text fontSize='sm' color={useColorModeValue('gray.300', 'gray.400')}>
                <strong>{props.header}</strong>
            </Text>
            <Text position="relative"
                  transition="transform 0.3s, font-size 0.3s"
                  _hover={{
                      transform: 'scale(1.1)',
                      _after: {
                          width: '100%'
                      }
                  }}
                  _after={{
                      content: '""',
                      position: 'absolute',
                      width: '0',
                      height: '1px',
                      bottom: '-5px',
                      left: '0',
                      backgroundColor: 'black',
                      transition: 'width 0.420s ease-in-out',
                  }}
                  fontSize='sm'>
                {props.detail}
                <CopyButtonComponent detail={props.detail}/>
            </Text>
        </Box>
    )
}

export default DataDetails;