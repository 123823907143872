import React from "react";
import {IconButton, useToast} from "@chakra-ui/react";
import {CopyIcon} from "@chakra-ui/icons";

interface CopyButtonProps {
    detail: any;
}
function CopyButtonComponent(props: CopyButtonProps) {
    const toast = useToast();

    const copyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text);
    };

    const handleCopy = () => {
        copyToClipboard(props.detail);
        toast({
            title: "Copié avec succès",
            description: "Le texte a été copié dans le presse-papiers.",
            status: "success",
            duration: 666,
            isClosable: true,
            position: "top",
        });
    };

    return (
        <IconButton
            icon={<CopyIcon/>}
            ml="auto"
            marginLeft={2}
            onClick={handleCopy}
            size="sm"
            aria-label="Settings"
            className="buttonStyle"
            boxShadow="md"
            _hover={{ transform: "scale(1.2)"}}
        />
    )
}

export default CopyButtonComponent;