import React, {useRef} from "react";
import {Box, Button, Input, useColorModeValue, useToast} from "@chakra-ui/react";
import axios from "axios";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getAuthorizedHeader} from "../../../../common/auth";

interface UploadButtonTasksProps {
    idTask: string;
    task: string;
    color: string;
    fetchData: () => void;
}

function UploadButtonTasksComponent(props: UploadButtonTasksProps) {
    const toast = useToast();
    const fileInputRef = useRef(null);

    async function uploadFile(file: File, type: 'pdf' | 'image' | 'dwg') {
        const formData = new FormData();
        formData.append(type, file);

        try {
            await axios.post(`/api/${props.task}/${type}/upload/${props.idTask}`, formData, {
                headers: {
                    ...getAuthorizedHeader(),
                    'Content-Type': 'multipart/form-data',
                },
            });
            toast({
                title: `${type.toUpperCase()} uploaded.`,
                description: `The new ${type} has been successfully uploaded.`,
                status: 'success',
                duration: 666,
                isClosable: true,
                position: "top",
            });
            props.fetchData();
        } catch (error) {
            toast({
                title: `Error uploading ${type}.`,
                description: `Error uploading ${type}: ${error}`,
                status: 'error',
                duration: 666,
                isClosable: true,
                position: "top",
            });
        }
    }

    function handleUpload(event: React.ChangeEvent<HTMLInputElement>) {
        const file = event.target.files ? event.target.files[0] : null;
        if (file) {
            const fileType = file.name.split('.').pop();
            switch (fileType) {
                case 'pdf':
                    uploadFile(file, 'pdf');
                    break;
                case 'dwg':
                    uploadFile(file, 'dwg');
                    break;
                case 'png':
                case 'jpg':
                case 'jpeg':
                case 'gif':
                    uploadFile(file, 'image');
                    break;
                default:
                    toast({
                        title: "Unsupported file type",
                        description: "Please upload a PDF, DWG, or image file.",
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                        position: "top",
                    });
            }
        }
    }
    const borderColor = useColorModeValue('#1C2942', '#4285F4');
    const hoverBgColor = useColorModeValue('#A3A3A3', '#A3A3A3');
    const placeholderColor = useColorModeValue('black', 'black');


    return (
        <Box onClick={(e) => e.stopPropagation()}>
            <Input
                type="file"
                ref={fileInputRef}
                onChange={(event) => handleUpload(event)}
                size="sm"
                variant="flushed"
                placeholder="Select Picture"
                style={{display: 'none'}}
            />
            <Button
                leftIcon={<FontAwesomeIcon icon={faUpload} />}
                size="sm"
                borderRadius="full"
                boxShadow="md"
                bg="transparent"
                rounded={"2xl"}
                border={`2px solid ${props.color}`}
                _hover={{bg: hoverBgColor}}
                _focus={{borderColor: borderColor, boxShadow: "none"}}
                _placeholder={{color: placeholderColor}}
                p={4}
                onClick={() => fileInputRef.current.click()}>
                Upload
            </Button>
        </Box>
    )
}

export default UploadButtonTasksComponent;