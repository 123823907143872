import React, {useState} from "react";
import {
    Box, Button, Divider, Flex, Heading, IconButton,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay, useBreakpointValue, useColorModeValue
} from "@chakra-ui/react";
import NewProjectDetails from "./NewProjectDetails";
import '../../../theme/Button.css'
import {useTranslation} from "react-i18next";
import ButtonComponent from "../../common/ButtonComponent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRotateRight} from "@fortawesome/free-solid-svg-icons";


interface NewProjectProps {
    callback: () => void;
}

function NewProjectComponent(props: NewProjectProps) {
    const {t} = useTranslation();
    const isSmallScreen = useBreakpointValue({base: true, md: false});
    const [newProject, setNewProject] = useState(false);
    const handleCloseModal = () => {
        setNewProject(false);
    };
    const handleOpenModal = () => {
        setNewProject(true);
    };

    return (
        <Box width={"100%"}>
            <Box mb={isSmallScreen ? 0 : 5}>
                <ButtonComponent text={"New Project"} callback={() => handleOpenModal()}/>
            </Box>
            <Modal isOpen={newProject}
                   onClose={handleCloseModal}
                   size={isSmallScreen ? "auto" : "6xl"}
            >
                <ModalOverlay bg='none'
                              backdropFilter='auto'
                              backdropBlur='2px'/>
                <ModalContent
                >
                    <Flex ml={6} justifyContent="space-between" w="100%" flexDirection={{ base: 'column', md: 'row' }}>
                        <Heading
                            size={"xl"}
                            color={useColorModeValue('gray.300', 'gray.400')}
                            lineHeight="tall"
                            marginBottom={isSmallScreen ? 3 : 4}
                            position="relative"
                            transition="transform 0.3s, font-size 0.3s"
                            _hover={{
                                transform: 'scale(1.1)',
                                _after: {
                                    width: isSmallScreen ? '95%' : '100%',
                                }
                            }}
                            _after={{
                                content: '""',
                                position: 'absolute',
                                width: '0',
                                height: '1px',
                                bottom: '-5px',
                                left: isSmallScreen ? '2' : '0',
                                backgroundColor: 'black',
                                transition: 'width 0.420s ease-in-out',
                            }}>
                            {t("NewProject")}
                        </Heading>
                        <IconButton
                            aria-label="Rotate Right"
                            icon={<FontAwesomeIcon icon={faRotateRight} />}
                            colorScheme="black"
                            variant="solid"
                            size="md"
                            mb={isSmallScreen ? 3 : 0}
                        />
                    </Flex>
                    <Divider color="gray.300" width="100%"/>
                    <ModalCloseButton
                        position="absolute"
                        top="1rem"
                        right="1rem"
                        bg="transparent"
                        _hover={{bg: 'transparent'}}
                        _active={{bg: 'transparent'}}
                        sx={{
                            '& svg': {
                                transition: 'all 0.2s ease-in-out',
                            },
                            '& svg path': {
                                stroke: 'white',
                                transition: 'stroke 0.3s ease-in-out',
                            },
                            '&:hover svg path': {
                                stroke: 'red',
                                transition: 'stroke 0.5s ease-in-out 0s',
                            },
                        }}/>
                    <ModalBody padding={isSmallScreen ? 3 : 5}>
                        <NewProjectDetails callback={props.callback} handleCloseModal={handleCloseModal}/>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Box>
    )
}

export default NewProjectComponent;