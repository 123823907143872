import React, {useState} from "react";
import {
    Box,
    Flex,
    Heading,
    useColorModeValue,
    useBreakpointValue, useDisclosure,
} from "@chakra-ui/react";
import {motion} from "framer-motion";
import SearchComponent from "../common/SearchComponent";
import ButtonComponent from "../common/ButtonComponent";
import {useTranslation} from "react-i18next";
import RdvDetaile from "./RdvDetaile";
import NewAppointmentComposent from "./NewAppointmentComposent";
import axios from "axios";
import {getAuthorizedHeader} from "../../common/auth";
import {parseEventDate} from "../../screens/CalendarScreen";

export interface IAppointment {
    _id: string;
    name: string;
    address: string;
    type: string;
    note: string;
    participants: string[];
    date: string;
    timeStart: string;
    timeEnd: string;
    email?: string;
    phone?: string;
}

interface AppointmentComponentProps {
    events: IAppointment[];
    fetchAppointments: () => void;
}

function AppointmentComponent({events, fetchAppointments}: AppointmentComponentProps) {
    const isSmallScreen = useBreakpointValue({base: true, md: false});
    const [searchTerm, setSearchTerm] = useState("");
    const {t} = useTranslation();
    motion(Box);

    const {isOpen, onOpen, onClose} = useDisclosure();

    const handleSearchChange = (term: string) => {
        setSearchTerm(term);
    };
    return (
        <Box
            bg={useColorModeValue("gray.200", "gray.700")}
            width={isSmallScreen ? 'calc("100% ")' : "50%"}
            rounded="md"
            boxShadow="md"
            flexDirection="column"
            justifyContent="space-around"
            alignItems="center"
            textAlign="center"
            padding={isSmallScreen ? 3 : 10}
            marginX={isSmallScreen ? 3 : 10}
            my={isSmallScreen ? 3 : 5}
        >
            <Flex
                justifyContent="space-between"
                w="100%"
                flexDirection={'column'}
                marginBottom={isSmallScreen ? 3 : 5}
            >
                <Heading
                    color={useColorModeValue('gray.300', 'gray.400')}
                    lineHeight="tall"
                    marginBottom={3}
                    position="relative"
                    transition="transform 0.3s, font-size 0.3s"
                    _hover={{
                        transform: 'scale(1.1)',
                        _after: {
                            width: isSmallScreen ? '95%' : '100%',
                        }
                    }}
                    _after={{
                        content: '""',
                        position: 'absolute',
                        width: '0',
                        height: '1px',
                        bottom: '-5px',
                        left: isSmallScreen ? '2' : '0',
                        backgroundColor: 'black',
                        transition: 'width 0.420s ease-in-out',
                    }}>
                    Rendez-vous
                </Heading>
                <ButtonComponent text={"New Meeting"} callback={onOpen}/>
            </Flex>
            <RdvDetaile events={events}/>
            <NewAppointmentComposent isOpen={isOpen} onClose={onClose} onSave={fetchAppointments}/>
        </Box>
    );
}

export default AppointmentComponent;
