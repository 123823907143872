import React, {useEffect, useState} from 'react';
import {
    Text,
    Flex,
    Box,
    Heading,
    Highlight,
    useBreakpointValue,
    useColorModeValue,
    Switch,
    Divider,
    FormControl, FormLabel
} from '@chakra-ui/react';
import EmployeeProfileCard from './EmployeesProfileCard';
import TeamList from './TeamList';
import {useTranslation} from 'react-i18next';
import NewTeam from "./New_team";
import axios from "axios";
import {getAuthorizedHeader} from "../../common/auth";


function Employers() {
    const {t} = useTranslation();
    const isSmallScreen = useBreakpointValue({base: true, md: false});
    const [showTeams, setShowTeams] = useState(false);
    const [teams, setTeams] = useState([]);

    const fetchTeams = async () => {
        try {
            const response = await axios.get('/api/teams', {
                headers: getAuthorizedHeader(),
            });
            setTeams(response.data);
        } catch (error) {
            console.error('Error fetching employees:', error);
        }
    };

    async function handleTeamFormSubmit(teamName: string) {
        try {
            const response = await axios.put('/api/team', {name: teamName}, {
                headers: getAuthorizedHeader(),
            });
            alert('Team successful created!');
            await fetchTeams();
        } catch (e) {
            console.error(e);
            alert('Unable to create team!')
        }
    }

    useEffect(() => {
        fetchTeams();
    }, []);

    return (
        <Flex
            bg={useColorModeValue("gray.200", "gray.700")}
            rounded="md"
            boxShadow="md"
            width={isSmallScreen ? "100%" : "50%"}
            marginRight={isSmallScreen ? 0 : 5}
            textAlign={"center"}
            direction="column"
        >
            <Box paddingY="2" justifyContent={"flex-start"} paddingRight={2} alignSelf="flex-end">
                <FormControl display="flex" alignItems="center">
                    <FormLabel size='lg' htmlFor="email-alerts" mb="0">
                        {showTeams ? t("Employers") : t("Team")}
                    </FormLabel>
                    <Switch id="email-alerts" colorScheme="green" size='lg' isChecked={showTeams}
                            onChange={() => setShowTeams(!showTeams)}/>
                </FormControl>
            </Box>
            <Box paddingRight={1} paddingY="8" width="100%" minHeight={window.innerHeight - 174}
                 maxHeight={window.innerHeight - 174} overflowY="scroll">
                <Heading alignItems="flex-start" fontSize='4xl' color="gray.500">
                    {showTeams ? (
                        <Flex alignItems="flex-start">
                            {t("Team")}
                        </Flex>
                    ) : (
                        t("Employers")
                    )}</Heading>
                <Divider marginY={2} width="100%"/>
                {showTeams ? (
                    <Box>
                        <NewTeam addTeam={handleTeamFormSubmit}/>
                        <TeamList fetchTeams={fetchTeams} teams={teams}/>
                    </Box>
                ) : (
                    <EmployeeProfileCard/>
                )}
            </Box>
        </Flex>
    );
}

export default Employers;
