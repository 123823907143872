import React from 'react';
import {
    Button,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter,
    useDisclosure, IconButton,
    Box,
} from '@chakra-ui/react';
import {DeleteIcon} from "@chakra-ui/icons";

interface SupProps {
    onClickDelete: () => Promise<void>;
    callback: () => Promise<void>;
}

function SupComponent(props: SupProps) {
    const {isOpen, onOpen, onClose} = useDisclosure();
    const cancelRef = React.useRef(null);

    function handleDelete() {
        props.onClickDelete()
            .then(() => {
                console.log('Delete operation successful.');
                props.callback();
            })
            .catch((error) => {
                console.error('Delete operation failed:', error);
            })
            .finally(() => {
                onClose();
            });
    }


    return (
        <Box onClick={(e) => e.stopPropagation()}>
            <IconButton
                icon={<DeleteIcon/>}
                colorScheme="red"
                size="sm"
                onClick={onOpen}
                aria-label={`Supprimer la photo`}
            />

            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Supprimer
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Are you sure? You can't undo this action afterwards.
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Cancel
                            </Button>
                            <Button colorScheme="red" ml={3} onClick={handleDelete}>
                                Delete
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </Box>
    );
};

export default SupComponent;
