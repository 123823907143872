import React, {useState} from 'react';
import {
    Box, Button,
    CircularProgress,
    CircularProgressLabel, Divider,
    Flex,
    Heading,
    IconButton,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text, useBreakpointValue,
    useColorModeValue,
    useDisclosure,
} from '@chakra-ui/react';
import {ArrowBackIcon, ArrowForwardIcon, ChevronLeftIcon, ChevronRightIcon} from '@chakra-ui/icons';

function getColor(value: number): string {
    if (value <= 25) {
        return 'red.400';
    } else if (value <= 50) {
        return 'orange.400';
    } else if (value <= 75) {
        return 'yellow.400';
    } else {
        return 'green.400';
    }
}

function Stat() {
    const [currentStatIndex, setCurrentStatIndex] = useState(0);
    const [selectedStat, setSelectedStat] = useState<{ value: number | undefined, label: string }>({
        value: undefined,
        label: ''
    });
    const {isOpen, onOpen, onClose} = useDisclosure();

    const stats = [
        {label: 'Stat 1', value: 85},
        {label: 'Stat 2', value: 30},
        {label: 'Stat 3', value: 60},
        {label: 'Stat 4', value: 40},
    ];

    const nextStat = () => setCurrentStatIndex((prevIndex) => (prevIndex + 1) % stats.length);
    const prevStat = () => setCurrentStatIndex((prevIndex) => (prevIndex - 1 + stats.length) % stats.length);

    let displayStats = stats.slice(currentStatIndex, currentStatIndex + 3);
    if (displayStats.length < 3) {
        displayStats = displayStats.concat(stats.slice(0, 3 - displayStats.length));
    }
    const isSmallScreen = useBreakpointValue({base: true, md: false});

    const handleStatClick = (stat: {
        value: number;
        label: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactPortal | Iterable<React.ReactNode>;
    }) => {
        const labelAsString = typeof stat.label === 'string' ? stat.label : String(stat.label);
        setSelectedStat({...stat, label: labelAsString});
        onOpen();
    };
    const handleNextStatModal = () => {
        setCurrentStatIndex((prevIndex) => (prevIndex + 1) % stats.length);
    };

    const handlePrevStatModal = () => {
        setCurrentStatIndex((prevIndex) => (prevIndex - 1 + stats.length) % stats.length);
    };


    return (
        <Flex direction="column" alignItems="center" mt={isSmallScreen ? 5 : 10} marginLeft={isSmallScreen ? 0 : 5}
              mb={0} p={5} textAlign="center"
              justifyContent="center"
              position="relative"
              h={"100%"}
              rounded="md"
              boxShadow="md"
              bg={useColorModeValue("gray.200", "gray.700")}
              overflow="hidden"
        >
            <Heading mb={4}>Statuts</Heading>
            <Flex
                direction="row"
                justifyContent="center"
                alignItems="center"
                position="relative"
                w="full"
            >
                <IconButton
                    aria-label="Previous stat"
                    icon={<ArrowBackIcon/>}
                    onClick={prevStat}
                    position="absolute"
                    left={0}
                    zIndex="2"
                    top="50%"

                    transform="translateY(-50%)"
                />
                {displayStats.map((stat: {
                    value: number;
                    label: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | Iterable<React.ReactNode>;
                }, index: React.Key) => (
                    <Box
                        key={index}
                        p={2}
                        flex="none"
                        marginTop={3}
                        onClick={() => handleStatClick(stat)}
                        cursor="pointer"
                    >
                        <CircularProgress value={stat.value} color={getColor(stat.value)} size="150px">
                            <CircularProgressLabel>
                                <Flex direction="column" align="center">
                                    <Heading size="sm" mb={1}>{stat.label}</Heading>
                                    <Text fontSize="xl">{`${stat.value}%`}</Text>
                                </Flex>
                            </CircularProgressLabel>
                        </CircularProgress>
                    </Box>
                ))}
                <IconButton
                    aria-label="Next stat"
                    icon={<ArrowForwardIcon/>}
                    onClick={nextStat}
                    position="absolute"
                    right={0}
                    zIndex="2"
                    top="50%"
                    transform="translateY(-50%)"
                />
            </Flex>

            <Modal isOpen={isOpen} onClose={onClose} isCentered>
                <ModalOverlay bg='none'
                              backdropFilter='auto'
                              backdropBlur='2px'/>
                <ModalContent>
                    <ModalHeader>Détails de {stats[currentStatIndex].label}</ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>
                        <Flex alignItems="center">
                            <IconButton
                                aria-label="Stat précédent"
                                icon={<ChevronLeftIcon/>}
                                mr="auto"
                                onClick={handlePrevStatModal}
                            />
                            <Text flex="1" textAlign="center">Value: {stats[currentStatIndex].value}%</Text>
                            <IconButton
                                aria-label="Stat suivant"
                                icon={<ChevronRightIcon/>}
                                ml="auto"
                                onClick={handleNextStatModal}
                            />
                        </Flex>
                    </ModalBody>
                </ModalContent>
            </Modal>

        </Flex>
    );
}

export default Stat;
