import React, {useState} from "react";
import {Box, Divider, Heading, useBreakpointValue, useColorModeValue} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import TaskComponent from "./TaskComponent";


interface TasksProps {
    idProject: string;
    idSignedQuote: string;
    idPreVisit: string;
    idCalculationNote: string;
    idPlan: string;
    idOrdersWithDeliveryDate: string;
    idEquipmentRental: string;
    idDelivery: string;
    idAccommodations: string;
    idPhotos: string;
    idInvoicing: string;
    callback: () => void;
}

function TasksComponent(props: TasksProps) {
    const isSmallScreen = useBreakpointValue({base: true, md: false});
    const {t} = useTranslation();

    const [updateCount, setUpdateCount] = useState(0);

    function handleCallback() {
        props.callback();
        setUpdateCount(updateCount => updateCount + 1);
    }

    return (
        <Box padding={5}
             bg={useColorModeValue('white', 'gray.600')}
             border-radius={4}
             rounded="md"
             boxShadow="md"
             width={isSmallScreen ? "100%" : 'calc(50% + 10px)'}>
            <Heading alignItems="flex-start" fontSize='2xl' color="gray.300">{t("Tasks")}</Heading>
            <Divider width="100%"/>
            <TaskComponent key={`${props.idSignedQuote}-${updateCount}`} idProject={props.idProject}
                           idTask={props.idSignedQuote} task={'signedQuote'} image={true} pdf={true} dwg={true}
                           callback={handleCallback}/>
            <TaskComponent key={`${props.idPreVisit}-${updateCount}`} idProject={props.idProject}
                           idTask={props.idPreVisit} task={'preVisit'} image={false} pdf={false} dwg={false}
                           callback={handleCallback}/>
            <TaskComponent key={`${props.idCalculationNote}-${updateCount}`} idProject={props.idProject}
                           idTask={props.idCalculationNote} task={'calculationNote'} image={true} pdf={true} dwg={true}
                           callback={handleCallback}/>
            <TaskComponent key={`${props.idPlan}-${updateCount}`} idProject={props.idProject}
                           idTask={props.idPlan} task={'plan'} image={true} pdf={true} dwg={true}
                           callback={handleCallback}/>
            <TaskComponent key={`${props.idOrdersWithDeliveryDate}-${updateCount}`} idProject={props.idProject}
                           idTask={props.idOrdersWithDeliveryDate} task={'ordersWithDeliveryDate'} image={true}
                           pdf={true} dwg={true}
                           callback={handleCallback}/>
            <TaskComponent key={`${props.idEquipmentRental}-${updateCount}`} idProject={props.idProject}
                           idTask={props.idEquipmentRental} task={'equipmentRental'} image={true} pdf={true} dwg={true}
                           callback={handleCallback}/>
            <TaskComponent key={`${props.idDelivery}-${updateCount}`} idProject={props.idProject}
                           idTask={props.idDelivery} task={'delivery'} image={true} pdf={true} dwg={true}
                           callback={handleCallback}/>
            <TaskComponent key={`${props.idAccommodations}-${updateCount}`} idProject={props.idProject}
                           idTask={props.idAccommodations} task={'accommodations'} image={true} pdf={true} dwg={true}
                           callback={handleCallback}/>
            <TaskComponent key={`${props.idPhotos}-${updateCount}`} idProject={props.idProject}
                           idTask={props.idPhotos} task={'photos'} image={true} pdf={true} dwg={true}
                           callback={handleCallback}/>
            <TaskComponent key={`${props.idInvoicing}-${updateCount}`} idProject={props.idProject}
                           idTask={props.idInvoicing} task={'invoicing'} image={true} pdf={true} dwg={true}
                           callback={handleCallback}/>
        </Box>
    )
}

export default TasksComponent;