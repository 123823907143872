import React, {useEffect, useState} from "react";
import {
    Box,
    Flex, IconButton,
    useColorModeValue, useDisclosure,
} from "@chakra-ui/react";
import HomeButtonComponent from "./HomeButtonComponent";
import LinksNavComponent from './LinksNavComponent';
import {SettingsIcon} from '@chakra-ui/icons';
import {useNavigate} from 'react-router-dom';
import DraggableButton from "../common/DraggableButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGear} from "@fortawesome/free-solid-svg-icons";

const NavBarComponent = () => {
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(false);
    const bgColor = useColorModeValue("gray.300", "gray.600");

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const [hovering, setHovering] = useState(false);
    const hoverIcon = <FontAwesomeIcon icon={faGear} spin />;
    const normalIcon = <FontAwesomeIcon icon={faGear} />;

    return (
        <Box
            width="100%"
            id="Home"
            justifyContent="space-between"
            backgroundColor={useColorModeValue("gray.200", "gray.700")}
            p="15px"
            boxShadow="0px 2px 4px rgba(0, 0, 0, 0.2)"
        >
            <Flex
                h={16}
                width={"100%"}
                alignItems="center"
                justifyContent="space-between"
                direction={"row"}
            >
                {isMobile && <><LinksNavComponent/><HomeButtonComponent/></>}
                {!isMobile && <><HomeButtonComponent/><LinksNavComponent/></>}

                <Flex alignItems="center" margin={isMobile ? 0 : 3} mr={isMobile ? 3: 0} padding={0}>
                    <IconButton
                        onClick={() => navigate("/settings")}
                        icon={hovering ? hoverIcon : normalIcon}
                        size="md"
                        aria-label="Settings"
                        boxShadow="md"
                        _hover={{ transform: "scale(1.1)" }}
                        onMouseEnter={() => setHovering(true)}
                        onMouseLeave={() => setHovering(false)}
                        marginX={3}
                    />
                    <DraggableButton/>
                </Flex>
            </Flex>
        </Box>
    );
};

export default NavBarComponent;
