import React, {useState} from 'react';
import {
  Badge,
  Box,
  Flex,
  Heading,
  IconButton,
  SimpleGrid,
  Tooltip,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react';
import {motion} from 'framer-motion';
import CountryFlag from 'react-country-flag';
import {useTranslation} from 'react-i18next';

function Language() {
  const isSmallScreen = useBreakpointValue({base: true, md: false});
  const {i18n} = useTranslation();
  const {t} = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng).then(() => console.log('Language Updated!'));
    setSelectedLanguage(lng);
  };

  const languages = [
    {code: 'en', name: t("English"), flag: <CountryFlag countryCode="GB" svg/>},
    {code: 'fr', name: t("Français"), flag: <CountryFlag countryCode="FR" svg/>},
    {code: 'es', name: t("Español"), flag: <CountryFlag countryCode="ES" svg/>},
  ];

  return (
    <motion.div
      initial={{opacity: 0, y: -20}}
      animate={{opacity: 1, y: 0}}
      exit={{opacity: 0, y: -20}}
      transition={{duration: 0.5}}
    >
      <Flex
        bg={useColorModeValue("gray.200", "gray.700")}
        rounded="md"
        boxShadow="md"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        marginLeft={isSmallScreen ? 0 : 5}
        marginTop={isSmallScreen ? 3 : 0}
        textAlign={"center"}
      >
        <Box p="8" width="100%" height="100%">


          <Flex align="center" justify="space-between">
            <Heading
                size={"xl"}
                color={useColorModeValue('gray.300', 'gray.400')}
                lineHeight="tall"
                marginBottom={isSmallScreen ? 3 : 4}
                position="relative"
                transition="transform 0.3s, font-size 0.3s"
                _hover={{
                transform: 'scale(1.1)',
                _after: {
                    width: isSmallScreen ? '95%' : '100%',
                }
            }}
                _after={{
                content: '""',
                position: 'absolute',
                width: '0',
                height: '1px',
                bottom: '-5px',
                left: isSmallScreen ? '2' : '0',
                backgroundColor: 'black',
                transition: 'width 0.420s ease-in-out',
            }}>{t("Language")}</Heading>
            <Badge colorScheme="teal">{i18n.language.toUpperCase()}</Badge>
          </Flex>


          {!isSmallScreen &&
            <SimpleGrid columns={3} spacing={4}>
              {languages.map((lang) => (
                <Tooltip key={lang.code} label={lang.name} placement="top">
                  <IconButton
                    aria-label={lang.name}
                    onClick={() => changeLanguage(lang.code)}
                    fontSize="lg"
                    colorScheme={selectedLanguage === lang.code ? 'teal' : 'gray'}
                    variant={selectedLanguage === lang.code ? 'solid' : 'outline'}
                    _hover={{scale: 1.2}}
                    isActive={selectedLanguage === lang.code}
                    style={{
                      marginTop: isSmallScreen ? '20px' : '30px',
                      width: isSmallScreen ? '100%' : 'auto',
                      border: selectedLanguage !== lang.code ? '1px solid gray' : 'none',
                    }}
                  >
                    {lang.flag}
                  </IconButton>
                </Tooltip>
              ))}
            </SimpleGrid>}


          {isSmallScreen &&
            <Box display="flex"
                 flexDirection="column"
                 alignItems="center"
                 justifyContent="center">
              <SimpleGrid columns={2} spacing={4} width={"100%"}>
                {languages.slice(0, 2).map((lang) => (
                  <Tooltip key={lang.code} label={lang.name} placement="top">
                    <IconButton
                      aria-label={lang.name}
                      onClick={() => changeLanguage(lang.code)}
                      fontSize="lg"
                      colorScheme={selectedLanguage === lang.code ? 'teal' : 'gray'}
                      variant={selectedLanguage === lang.code ? 'solid' : 'outline'}
                      _hover={{scale: 1.2}}
                      isActive={selectedLanguage === lang.code}
                      style={{
                        marginTop: isSmallScreen ? '20px' : '30px',
                        width: isSmallScreen ? '100%' : 'auto',
                        border: selectedLanguage !== lang.code ? '1px solid gray' : 'none',
                      }}
                    >
                      {lang.flag}
                    </IconButton>
                  </Tooltip>
                ))}
              </SimpleGrid>
              {languages.slice(2).map((lang, index) => (
                <Tooltip key={lang.code} label={lang.name} placement="top">
                  <IconButton
                    aria-label={lang.name}
                    onClick={() => changeLanguage(lang.code)}
                    fontSize="lg"
                    colorScheme={selectedLanguage === lang.code ? 'teal' : 'gray'}
                    variant={selectedLanguage === lang.code ? 'solid' : 'outline'}
                    _hover={{scale: 1.2}}
                    isActive={selectedLanguage === lang.code}
                    style={{
                      marginTop: isSmallScreen ? '20px' : '30px',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      width: 'calc(50% - 8px)',
                      border: selectedLanguage !== lang.code ? '1px solid gray' : 'none',
                    }}
                  >
                    {lang.flag}
                  </IconButton>
                </Tooltip>
              ))}
            </Box>
          }


        </Box>
      </Flex>
    </motion.div>
  );
}

export default Language;
