import {createBrowserRouter} from "react-router-dom";
import HomeScreen from "../screens/HomeScreen";
import CalendarScreen from "../screens/CalendarScreen";
import LoginScreen from "../screens/LoginScreen";
import TasksScreen from "../screens/TasksScreen";
import AdminScreen from "../screens/AdminScreen";
import ProjectScreen from "../screens/ProjectScreen";
import SettingsScreen from '../screens/SettingsScreen';
import MapScreen from "../components/map/MapComposent";
import UsineScreen from "../screens/UsineScreen";


const router = createBrowserRouter([
    {
        path: "/",
        element: <HomeScreen/>,
    },
    {
        path: "/settings",
        element: <SettingsScreen/>,
    },
    {
        path: "/login",
        element: <LoginScreen/>,
    },
    {
        path: "/calendar",
        element: <CalendarScreen/>,
    },
    {
        path: "/tasks",
        element: <TasksScreen/>,
    },
    {
        path: "/admin",
        element: <AdminScreen/>,
    },
    {
        path: "/projects",
        element: <ProjectScreen/>,
    },
    {
        path: "/map",
        element: <MapScreen/>,
    },
    {
        path: "/production",
        element: <UsineScreen/>,
    },
]);

export default router;
