import React from "react";
import {Badge, Box, Divider, Heading, Text, useBreakpointValue, useColorModeValue} from "@chakra-ui/react";
import {getTagsColor} from "../../../common/task";

interface TagsProps {
    tags: string[];
}
function TagsComponent(props: TagsProps) {
    const isSmallScreen = useBreakpointValue({base: true, md: false});

    return (
        <Box padding={5}
             bg={useColorModeValue('white', 'gray.600')}
             border-radius={4}
             rounded="md"
             boxShadow="md"
             width={"100%"}
             marginTop={isSmallScreen ? 0 : 5}
             height={isSmallScreen ? "100%" : "25%"}
        >
            <Heading alignItems="flex-start" fontSize='2xl' color="gray.300">Tags</Heading>
            <Divider  width="100%"/>
            {props.tags.map((team, index) => (
                <Badge key={index} colorScheme={getTagsColor(team)} mr={2}>
                    {team}
                </Badge>
            ))}
        </Box>
    )
}

export default TagsComponent;