import axios from "axios";
import {getAuthorizedHeader} from "./auth";

async function fetchProgress(idProject: string) {
    try {
        const response = await axios.get(`/api/project/progress/` + idProject, {
            headers: getAuthorizedHeader(),
        });
        if (response.data && response.data.progress) {
            return parseFloat(response.data.progress);
        }
    } catch (error) {
        console.error('Error fetching project progress', error);
    }
    return 0;
}

export {fetchProgress};