import React from "react";
import {AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, useColorModeValue} from "@chakra-ui/react";
import ClientComponent from "../projectDetails/ClientComponent";
import {useTranslation} from "react-i18next";


interface ProjectMobileCategoriesComponent {
    title: string
    component: any;
}

function ProjectMobileCategoriesComponent(props: ProjectMobileCategoriesComponent) {
    const {t} = useTranslation();
    return (
        <AccordionItem m={0} p={0}>
            <AccordionButton
                width={"100%"}
                marginTop={2}
                mx={0}
                mb={0}
                backgroundColor={useColorModeValue('white', 'gray.600')}
                borderRadius="md"
            >
                <Box flex="1" textAlign="left">{t(`${props.title}`)}</Box>
                <AccordionIcon/>
            </AccordionButton>
            <AccordionPanel mx={0} mt={2} p={0}>
                {props.component}
            </AccordionPanel>
        </AccordionItem>
    )
}

export default ProjectMobileCategoriesComponent;