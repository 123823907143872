import React, { useState } from 'react';
import {
    Box, Button,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    Icon,
    IconButton,
    ModalOverlay,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverHeader,
    PopoverTrigger,
    Tag,
    TagLabel, Text,
    useColorModeValue,
    useDisclosure,
} from '@chakra-ui/react';
import {BellIcon, CalendarIcon, WarningIcon, CheckIcon, AddIcon, DownloadIcon} from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import _default from "chart.js/dist/plugins/plugin.tooltip";

const DraggableButton = () => {
    const [isMobile] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const bgColor = useColorModeValue("rgba(245, 245, 245, 1)", "#A3A3A3");
    const fond = useColorModeValue("rgba(237, 242, 247, 0.9)", "rgba(45, 55, 72, 0.9)");
    const borderColor = useColorModeValue('green', 'green');
    const borderColorR = useColorModeValue('red', 'red');
    const hoverBgColor = useColorModeValue('#A3A3A3', '#A3A3A3');
    const placeholderColor = useColorModeValue('black', 'black');

    const notifications = [
        {
            id: 1,
            text: 'Demande un RDV',
            type: 'rendezvous',
            requester: 'Alice Dupont',
            date: '2024-03-28',
            location: 'Bureau Paris',
            subject: 'Réunion de projet',
        },
        {
            id: 2,
            text: 'est en retard',
            type: 'retard',
            projectName: 'Projet Alpha',
            delayDays: 3,
            blockingTask: 'Validation du design',
        },
        {
            id: 3,
            text: 'terminée',
            type: 'tache_terminee',
            projectName: 'Projet Beta',
            completedTask: 'Développement de la fonctionnalité X',
        },
        {
            id: 4,
            text: 'Nouveau projet disponible',
            type: 'nouveau_projet',
            projectName: 'Projet de Snoup',
            endDate: '2024-06-30',
        },
    ];

    type NotificationType = {
        id: number;
        text: string;
        type: string;
        requester?: string;
        date?: string;
        location?: string;
        subject?: string;
        projectName?: string;
        delayDays?: number;
        blockingTask?: string;
        completedTask?: string;
        endDate?: string;
    };

    const getNotificationDetails = (notification: { id: number; text: string; type: string; requester: string; date: string; location: string; subject: string; projectName?: undefined; delayDays?: undefined; blockingTask?: undefined; completedTask?: undefined; endDate?: undefined; } | { id: number; text: string; type: string; projectName: string; delayDays: number; blockingTask: string; requester?: undefined; date?: undefined; location?: undefined; subject?: undefined; completedTask?: undefined; endDate?: undefined; } | { id: number; text: string; type: string; projectName: string; completedTask: string; requester?: undefined; date?: undefined; location?: undefined; subject?: undefined; delayDays?: undefined; blockingTask?: undefined; endDate?: undefined; } | { id: number; text: string; type: string; projectName: string; endDate: string; requester?: undefined; date?: undefined; location?: undefined; subject?: undefined; delayDays?: undefined; blockingTask?: undefined; completedTask?: undefined; }) => {
        switch (notification.type) {
            case 'rendezvous':
                return (
                    <span>Demande de RDV par <strong>{notification.requester}</strong> le <strong>{notification.date}</strong> à <strong>{notification.location}</strong> pour "<strong>{notification.subject}</strong>".</span>
                );
            case 'retard':
                return (
                    <span>Le projet <strong>{notification.projectName}</strong> est en retard de <strong>{notification.delayDays}</strong> jours à cause de "<strong>{notification.blockingTask}</strong>".</span>
                );
            case 'tache_terminee':
                return (
                    <span>La tâche "<strong>{notification.completedTask}</strong>" du projet <strong>{notification.projectName}</strong> a été terminée.</span>
                );
            case 'nouveau_projet':
                return (
                    <span>Nouveau projet "<strong>{notification.projectName}</strong>" disponible jusqu'au <strong>{notification.endDate}</strong>.</span>
                );
            default:
                return 'Détails non disponibles.';
        }
    };


    const getColorScheme = (type: any) => {
        switch (type) {
            case 'rendezvous':
                return 'blue';
            case 'retard':
                return 'red';
            case 'tache_terminee':
                return 'green';
            case 'nouveau_projet':
                return 'purple';
            default:
                return 'gray';
        }
    };

    const getIconByType = (type: string) => {
        switch (type) {
            case 'rendezvous':
                return <CalendarIcon margin={4} />;
            case 'retard':
                return <WarningIcon margin={4} />;
            case 'tache_terminee':
                return <CheckIcon margin={4} />;
            case 'nouveau_projet':
                return <AddIcon margin={4} />;
            default:
                return null;
        }
    };

    const renderPopoverHeader = (notification: { type: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode>; }) => {
        if (notification.type === 'rendezvous') {
            return (
                <>
                    <PopoverContent bg={bgColor} color={'black'} rounded={"2xl"} border={"1px"}
                                    borderColor={getColorScheme(notification.type)} >
                        <PopoverCloseButton
                            position="absolute"
                            top="1rem"
                            right="1rem"
                            bg="transparent"
                            _hover={{ bg: "rgba( 245 , 245 , 245, 0.9)" }}
                            _active={{ bg: "rgba( 245 , 245 , 245, 0.9)" }}
                            sx={{
                                '& svg': {
                                    transition: 'all 0.2s ease-in-out',
                                },
                                '& svg path': {
                                    stroke: 'white',
                                    transition: 'stroke 0.3s ease-in-out',
                                },
                                '&:hover svg path': {
                                    stroke: 'red',
                                    transition: 'stroke 0.5s ease-in-out 0s',
                                },
                            }}
                        />
                        <PopoverArrow />
                        <PopoverHeader>
                            Demande de RDV
                            <Box mt={2}>
                                <Button
                                    color={'black'}
                                    size="sm" mt={2}
                                    rounded={"2xl"}
                                    border={`3px solid ${borderColor}`}
                                    _hover={{bg: hoverBgColor}}
                                    _focus={{borderColor: borderColor, boxShadow: "none"}}
                                    _placeholder={{color: placeholderColor}}
                                    width={{base: "20%", sm: "30%"}}
                                    mr={{base: 2, sm: 0}}
                                    marginTop={2}>Valider</Button>
                                <Button size="sm"
                                        color={'black'}
                                        marginLeft={3}
                                        mt={2}
                                        rounded={"2xl"}
                                        border={`3px solid ${borderColorR}`}
                                        _hover={{bg: hoverBgColor}}
                                        _focus={{borderColor: borderColorR, boxShadow: "none"}}
                                        _placeholder={{color: placeholderColor}}
                                        width={{base: "20%", sm: "30%"}}
                                        mr={{base: 2, sm: 0}}
                                        marginTop={2}>Refuser</Button>
                            </Box>
                        </PopoverHeader>
                    </PopoverContent>
                </>
            );
        }
    };

    return (
        <Box>
            <Box width={'100%'}
                 textAlign={isMobile ? 'center' : 'right'}
            >
                <IconButton
                    onClick={onOpen}
                    icon={<BellIcon />}
                    size="md"
                    aria-label="Notifications"
                    className="buttonStyle"
                    boxShadow="md"
                    _hover={{ transform: 'scale(1.1)', bg: bgColor }}
                    p={0}
                    m={0}
                />
            </Box>
            <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="xs">
                <DrawerOverlay bg='none'
                              backdropFilter='auto'
                              backdropBlur='2px'/>
                <DrawerContent bg={fond}>
                    <DrawerCloseButton
                        position="absolute"
                        top="1rem"
                        right="1rem"
                        bg="transparent"
                        _hover={{ bg: 'transparent' }}
                        _active={{ bg: 'transparent' }}
                        sx={{
                            '& svg': {
                                transition: 'all 0.2s ease-in-out',
                            },
                            '& svg path': {
                                stroke: 'white',
                                transition: 'stroke 0.3s ease-in-out',
                            },
                            '&:hover svg path': {
                                stroke: 'red',
                                transition: 'stroke 0.5s ease-in-out 0s',
                            },
                        }}/>
                    <DrawerHeader>Notifications</DrawerHeader>
                    <DrawerBody>
                        {notifications.map(notification => (
                            <Box key={notification.id} p={2} borderBottom="1px solid" borderColor="black" >
                                <Popover>
                                    <PopoverTrigger >
                                        <Tag
                                            border={"1px"}
                                            borderColor={getColorScheme(notification.type)}                                            size="lg"
                                            key="lg"
                                            variant="outline"
                                            rounded={"2xl"}
                                            color={'black'}
                                            bg="rgba( 245 , 245 , 245, 0.5)"
                                            transition="transform 0.3s"
                                            _hover={{ transform: 'scale(1.1)' }}
                                            boxShadow="lg"
                                        >
                                            <TagLabel>
                                                <Box display="flex" flexDirection={"row"} alignItems="center">
                                                    <Box>{getIconByType(notification.type)}</Box>
                                                    <Box  marginRight={3}>{getNotificationDetails(notification)}</Box>
                                                    <Box alignSelf="self-start"> <Text fontSize='xs'>now</Text> </Box>
                                                </Box>
                                            </TagLabel>
                                        </Tag>
                                    </PopoverTrigger>
                                    {renderPopoverHeader(notification)}

                                </Popover>
                            </Box>
                        ))}
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </Box>
    );
};

export default DraggableButton;
