import React, { useState } from 'react';
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Input,
    Textarea,
    useToast,
    Heading,
    VStack,
    Stack,
    useColorModeValue,
    Icon,
} from '@chakra-ui/react';
import { FaPaperPlane } from 'react-icons/fa';

const OrderForm: React.FC = () => {
    const [email, setEmail] = useState('');
    const [quantity, setQuantity] = useState('');
    const [message, setMessage] = useState('');
    const toast = useToast();

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        // Simulate sending an email
        sendEmail(email, quantity, message);

        // Show a success toast
        toast({
            title: "Commande envoyée.",
            description: "Votre commande de métal a été envoyée avec succès.",
            status: "success",
            duration: 5000,
            isClosable: true,
        });

        // Clear the form
        setEmail('');
        setQuantity('');
        setMessage('');
    };

    const sendEmail = (email: string, quantity: string, message: string) => {
        // Simulate email sending
        console.log(`Email: ${email}`);
        console.log(`Quantity: ${quantity}`);
        console.log(`Message: ${message}`);
    };

    return (
        <Box p={8} maxW="600px" mx="auto" bg={useColorModeValue('white', 'gray.800')} boxShadow="lg" borderRadius="lg">
            <VStack spacing={4} align="stretch">
                <Heading as="h2" size="lg" textAlign="center" mb={4}>
                    Passer une commande de métal
                </Heading>
                <form onSubmit={handleSubmit}>
                    <VStack spacing={4}>
                        <FormControl isRequired>
                            <FormLabel>Email</FormLabel>
                            <Input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Votre email"
                                focusBorderColor="blue.500"
                            />
                        </FormControl>
                        <FormControl isRequired>
                            <FormLabel>Quantité de métal</FormLabel>
                            <Input
                                type="number"
                                value={quantity}
                                onChange={(e) => setQuantity(e.target.value)}
                                placeholder="Quantité de métal"
                                focusBorderColor="blue.500"
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Message</FormLabel>
                            <Textarea
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                placeholder="Informations supplémentaires"
                                focusBorderColor="blue.500"
                            />
                        </FormControl>
                        <Button
                            type="submit"
                            colorScheme="blue"
                            rightIcon={<Icon as={FaPaperPlane} />}
                            width="full"
                        >
                            Envoyer la commande
                        </Button>
                    </VStack>
                </form>
            </VStack>
        </Box>
    );
};

export default OrderForm;
