import React, {useEffect, useState} from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    Button,
    Text,
    ModalCloseButton,
    Stack,
    TableContainer,
    Table,
    Tbody,
    Tr,
    Td,
    Thead,
    Th,
    HStack,
    IconButton,
    Box,
    Flex,
    useBreakpointValue, useColorModeValue,
} from '@chakra-ui/react';
import {AddIcon, CloseIcon} from "@chakra-ui/icons";
import SearchComponent from "../common/SearchComponent";
import axios from "axios";
import {getAuthorizedHeader} from "../../common/auth";

interface TeamDetailsModalProps {
    isOpen: boolean;
    onClose: () => void;
    fetchTeams: () => void;
    team: {
        _id: string;
        name: string;
        employees: string[];
    };
}

interface IUser {
    _id: string;
    lastname: string;
    firstname: string;
    job: string;
}

function TeamDetailsModal({isOpen, onClose, team, fetchTeams}: TeamDetailsModalProps) {
    if (!team) return null;
    const [isEditing, setIsEditing] = useState(false);
    const [searchEmployee, setSearchEmployee] = useState('');
    const [teamMembers, setTeamMembers] = useState([] as IUser[]);
    const [searchResults, setSearchResults] = useState([]);
    const isSmallScreen = useBreakpointValue({base: true, md: false});

    useEffect(() => {
        setIsEditing(false);
        setTeamMembers([]);
        if (team.employees.length > 0) {
            fetchAllTeamMembers();
        }
        handleSearch(searchEmployee);
    }, [team.employees]);

    async function updateTeamMembers() {
        try {
            const response = await axios.put(`/api/teams/${team._id}/members`, {
                members: teamMembers.map(member => member._id),
            }, {
                headers: getAuthorizedHeader(),
            });
            console.log('Team updated successfully:', response.data);
            fetchTeams();
            onClose();
        } catch (error) {
            console.error('Failed to update team members:', error);
        }
    };

    const handleSearch = async (searchQuery: string) => {
        setSearchEmployee(searchQuery);
        try {
            const response = await axios.get(`/api/employees/search?query=${searchQuery}`, {
                headers: getAuthorizedHeader(),
            });
            setSearchResults(response.data);
            console.log(response.data);
        } catch (error) {
            console.error('Error searching employees:', error);
        }
    };

    const handleAddMember = (member: any) => {
        if (teamMembers.find(m => m._id === member._id)) return;
        setTeamMembers([...teamMembers, member]);
    }

    const fetchAllTeamMembers = async () => {
        const membersDetails = await Promise.all(
            team.employees.map(async (userId) => {
                try {
                    const response = await axios.get(`/api/user/${userId}`, {
                        headers: getAuthorizedHeader(),
                    });
                    return response.data;
                } catch (error) {
                    console.error('Failed to fetch user details:', error);
                    return null;
                }
            })
        );
        setTeamMembers(membersDetails.filter(member => member !== null));
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay/>
            <ModalContent maxW={isSmallScreen ? "90%" : "30%"}>
                <ModalHeader>{team.name} - Edit Team</ModalHeader>
                <ModalCloseButton/>
                <ModalBody>
                    {isEditing && (
                        <Flex rounded="md"
                              boxShadow="md"
                              bg={useColorModeValue("gray.200", "gray.700")}
                              marginLeft={10} marginBottom={5} flexDirection={"column"}>
                            <SearchComponent value={searchEmployee} onChange={(e) => handleSearch(e)}/>
                            <Stack spacing={4} margin={2}>
                                {searchResults.map((employee) => (
                                    <Box key={employee._id} display="flex" justifyContent="space-between"
                                         alignItems="center" textAlign="center">
                                        {employee.firstname} {employee.lastname} - {employee.job}
                                        <IconButton
                                            aria-label="Add employee"
                                            icon={<AddIcon/>}
                                            onClick={() => handleAddMember(employee)}
                                        />
                                    </Box>
                                ))}
                            </Stack>
                        </Flex>
                    )}
                    <TableContainer>
                        <Table variant="simple">
                            <Thead>
                                <Tr>
                                    <Th>Name</Th>
                                    <Th>Job</Th>
                                    {isEditing && <Th>Action</Th>}
                                </Tr>
                            </Thead>
                            <Tbody>
                                {teamMembers.map((member) => (
                                    <Tr key={member._id}>
                                        <Td>{member.firstname} {member.lastname}</Td>
                                        <Td>{member.job}</Td>
                                        {isEditing && (
                                            <Td>
                                                <IconButton aria-label="Remove member" icon={<CloseIcon/>}
                                                            onClick={() => setTeamMembers(teamMembers.filter(m => m._id !== member._id))}/>
                                            </Td>
                                        )}
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </TableContainer>
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="blue" onClick={() => updateTeamMembers()}>Save Changes</Button>
                    <Button onClick={() => setIsEditing(!isEditing)}>{isEditing ? "Finish Editing" : "Edit"}</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default TeamDetailsModal;
