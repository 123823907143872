import React, {useState} from 'react';
import { Box, Heading, Text, Stack, useColorModeValue, Avatar, Icon, Flex } from '@chakra-ui/react';
import { FaUsers } from 'react-icons/fa';
import TeamDetailsModal from "./TeamDetailsModal";
import SupComponent from "../common/SupComponent";
import axios from "axios";
import {getAuthorizedHeader} from "../../common/auth";


const teamsData = [
    {
        id: 1,
        name: 'Équipe Montage 1',
        membersCount: 3,
        imageUrl: '/path-to-your-image/innovation.png',
        members: [
            { id: 1, name: 'Alice', firstname: 'Alice', job: 'Ingénieur' },
            { id: 2, name: 'Bob', firstname: 'Bob', job: 'Designer' },
            { id: 3, name: 'Charlie', firstname: 'Charlie', job: 'Chef de projet' },
        ],
    },
    {
        id: 2,
        name: 'Équipe Montage 2',
        membersCount: 2,
        imageUrl: '/path-to-your-image/development.png',
        members: [
            { id: 4, name: 'David', firstname: 'David', job: 'Développeur Front-end' },
            { id: 5, name: 'Eve', firstname: 'Eve', job: 'Développeur Back-end' },
        ],
    },
    {
        id: 3,
        name: 'Équipe Fondation',
        membersCount: 2,
        imageUrl: '/path-to-your-image/marketing.png',
        members: [
            { id: 6, name: 'Frank', firstname: 'Frank', job: 'Analyste de marché' },
            { id: 7, name: 'Grace', firstname: 'Grace', job: 'Spécialiste SEO' },
        ],
    },
];

interface TeamListProps {
    teams: any;
    fetchTeams: () => void;
}
function TeamList(props: TeamListProps) {
    const bgColor = useColorModeValue('#B6D8F2', '#2D4B73');
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 420);
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);


    const handleBoxClick = (team: { _id: number; name: string; membersCount: number; imageUrl: string; }) => {
        setSelectedTeam(team);
        setIsModalOpen(true);
    };

    async function deleteTeam(teamId: string) {
        try {
            await axios.delete('/api/team/' + teamId, {
                headers: getAuthorizedHeader(),
            });
            alert("Team deleted successfully!");
        } catch (error) {
            console.error("Error deleting user:", error);
        }
    }

    return (
        <Box paddingX={10} mt={5} maxHeight={`calc(100% - 70px)`} overflowY={"auto"} className={"custom-scrollbar"}>
            <Stack spacing={4}>
                {props.teams.map((team: any) => (
                    <Box
                        key={team._id}
                        shadow="md"
                        borderWidth="1px"
                        borderRadius="md"
                        bg={bgColor}
                        rounded="md"
                        boxShadow="md"
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        padding="4"
                        cursor="pointer"
                        width="100%"
                        onClick={() => handleBoxClick(team)}
                    >
                        <Avatar name={team.name} src={team.imageUrl} size="md" marginRight={4} />
                        <Flex flexDirection="column" flexGrow={1}>
                            <Heading fontSize="xl">{team.name}</Heading>
                            <Flex alignItems="center">
                                <Icon as={FaUsers} mr={2} />
                                <Text>{team.employees.length}</Text>
                            </Flex>
                        </Flex>
                        <Box onClick={(e) => e.stopPropagation()}>
                            <SupComponent onClickDelete={() => deleteTeam(team._id)} callback={async () => props.fetchTeams()}/>
                        </Box>
                    </Box>
                ))}
            </Stack>
            {selectedTeam && (
                <TeamDetailsModal
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    team={selectedTeam}
                 fetchTeams={props.fetchTeams}/>
            )}
        </Box>
    );
};

export default TeamList;
