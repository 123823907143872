import React from "react";
import {Box, Button, Flex, useColorModeValue} from "@chakra-ui/react";
import {getButtonStatusValidator} from "../../../../common/status";
import axios from "axios";
import {getAuthorizedHeader} from "../../../../common/auth";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil, faUpload} from "@fortawesome/free-solid-svg-icons";

interface TaskValidatorProps {
    idProject: string;
    task: string;
    status: string
    callback: () => void;
}

function TaskModificatorComponent(props: TaskValidatorProps) {
    const {t} = useTranslation();

    async function modifyTaskStatus() {
        try {
            const response = await axios.put('/api/' + props.task + '/modify/' + props.idProject, {}, {
                headers: getAuthorizedHeader(),
            });

            props.callback();
        } catch (error) {
            console.error('Error fetching Task:', error);
        }
    }

    const hoverBgColor = useColorModeValue(`purple.300`, `purple.600`);
    const placeholderColor = useColorModeValue('black', 'black');

    return (
        <Box onClick={(e) => e.stopPropagation()}>
            <Button
                leftIcon={<FontAwesomeIcon icon={faPencil}/>}
                size="sm"
                borderRadius="full"
                boxShadow="md"
                bg="transparent"
                rounded={"2xl"}
                border={`2px solid purple`}
                _hover={{bg: 'rgba(128, 0, 128, 0.1)'}}
                _focus={{boxShadow: "none"}}
                p={4}
                onClick={() => modifyTaskStatus()}
            >Modify</Button>
        </Box>
    )
}

export default TaskModificatorComponent;