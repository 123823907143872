import React from 'react';
import {useColorModeValue} from "@chakra-ui/react";

interface ButtonProps {
    text: string;
    callback: () => void;
}

function ButtonComponent(props: ButtonProps) {
    const bgColor = useColorModeValue('#A3A3A3', '#262F40');
    const textColor = useColorModeValue('#282936', '#fff');
    const [isHovered, setIsHovered] = React.useState(false);
    const dynamicStyles = {
        backgroundColor: isHovered ? bgColor : 'transparent',
        color: textColor,
    };
    return (
        <div>
            <button className="learn-more" onClick={props.callback}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    style={dynamicStyles}>
                <span className="circle" aria-hidden="true">
                  <span className="icon arrow"></span>
                </span>
                <span className="button-text">{props.text}</span>
            </button>
        </div>
    );
};

export default ButtonComponent;
