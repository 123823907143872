import React, {useEffect, useState} from 'react';
import {
  Box,
  Input,
  Button,
  FormControl,
  FormLabel,
  SimpleGrid,
} from '@chakra-ui/react';
import employeeDetailsModal from './EmployeeDetailsModal';

interface IModifyFormData {
  firstname: string;
  lastname: string;
  email: string;
  job: string;
  phone: string;
}

interface IModifyComponentProperties {
  formData: IModifyFormData;
  callback: (data: IModifyFormData) => void;
}

function ModifComponent(props: IModifyComponentProperties): React.JSX.Element {
  const [formData, setFormData] = useState(props.formData);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 500 || (window.innerWidth > 667 && window.innerWidth < 950));

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 500);
      setIsSmallScreen(window.innerWidth > 667 && window.innerWidth < 950);

    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = event.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleModify = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    props.callback(formData);
  };

  return (
    <Box width="100%" margin="auto">
      <form onSubmit={handleModify}>
        <SimpleGrid columns={isSmallScreen ? 1 : 2} spacing={5}>
          <FormControl id="firstname" isRequired>
            <FormLabel mt={5}>Firstname</FormLabel>
            <Input
              type="firstname"
              name="firstname"
              value={formData.firstname}
              onChange={handleChange}
              placeholder="Firstname"
              border="1px solid #4285F4"
              _hover={{bg: "#B6D8F2"}}
            />
          </FormControl>
          <FormControl id="lastname" isRequired>
            <FormLabel mt={isSmallScreen ? 0 : 5}>Lastname</FormLabel>
            <Input
              type="lastname"
              name="lastname"
              value={formData.lastname}
              onChange={handleChange}
              placeholder="Lastname"
              border="1px solid #4285F4"
              _hover={{bg: "#B6D8F2"}}
            />
          </FormControl>
          <FormControl id="job" isRequired>
            <FormLabel>Job</FormLabel>
            <Input
              type="job"
              name="job"
              value={formData.job}
              onChange={handleChange}
              placeholder="Job"
              border="1px solid #4285F4"
              _hover={{bg: "#B6D8F2"}}
            />
          </FormControl>
          <FormControl id="phone" isRequired>
            <FormLabel>Phone</FormLabel>
            <Input
              type="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              placeholder="Phone"
              border="1px solid #4285F4"
              _hover={{bg: "#B6D8F2"}}
            />
          </FormControl>
        </SimpleGrid>

        <FormControl id="email" isRequired marginY={5}>
          <FormLabel>Email</FormLabel>
          <Input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Email"
            border="1px solid #4285F4"
            _hover={{bg: "#B6D8F2"}}
          />
        </FormControl>
        <Button type="submit" colorScheme="teal" mt={5} width="100%">
          Save
        </Button>
      </form>
    </Box>
  );
}

export type {IModifyFormData};

export default ModifComponent;
