import React, {useEffect, useState} from "react";
import {
    Box,
    Button, Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text, Tooltip, useBreakpointValue, useColorModeValue
} from "@chakra-ui/react";
import axios from "axios";
import {getAuthorizedHeader} from "../../../common/auth";
import ProjectDetailsComponent from "../ProjectDetailsComponent";
import TaskDetailsComponent from "./TaskDetailsComponent";
import {fetchTask, getStatusColor} from "../../../common/status";
import {useTranslation} from "react-i18next";
import TaskModalComponent from "./TaskModalComponent";

interface TaskProps {
    idTask: string;
    idProject: string;
    task: string;
    pdf: boolean;
    image: boolean;
    dwg: boolean;
    callback: () => void;
}

interface TaskResponse {
    status: string;
}

function TaskComponent(props: TaskProps) {
    const {t} = useTranslation();
    const [status, setStatus] = useState(null as string);
    const [isLoading, setIsLoading] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const bgColor = useColorModeValue(`${getStatusColor(status)}.200`, `${getStatusColor(status)}.500`);

    function handleCloseModal() {
        setIsOpen(false);
    }

    function handleOpenModal() {
        setIsOpen(true);
    }

    function handleCallback() {
        setIsLoading(true);
        fetchTask(props.idTask, props.task).then(r => setStatus(r)).finally(() => setIsLoading(false));
        props.callback();
    }

    useEffect(() => {
        setIsLoading(true);
        fetchTask(props.idTask, props.task).then(r => setStatus(r)).finally(() => setIsLoading(false));
    }, [props.idTask, props.task]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (!status) {
        return <div>Task data not available</div>;
    }
    return (
        <Box>
            <Box
                borderWidth="2px"
                borderColor={`${getStatusColor(status)}.900`}
                bg={bgColor}
                rounded="lg"
                width="100%"
                marginTop={2}
                _hover={{ transform: 'scale(1.05)' }}
                transition="transform 0.2s"
                padding={2}
                minHeight="40px"
                justifyContent="center"
                alignItems="center"
                display="flex"
                onClick={handleOpenModal}
                cursor="pointer"
            >
                <Tooltip label={t(`${props.task}`)}>
                    <Text fontWeight="semibold" textAlign="center" overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
                        {t(`${props.task}`)}
                    </Text>
                </Tooltip>
            </Box>
            <TaskModalComponent isOpen={isOpen} handleCloseModal={handleCloseModal} handleCallback={handleCallback} idProject={props.idProject} idTask={props.idTask} task={props.task} status={status}/>
        </Box>
    )
}

export default TaskComponent;