import React, {useEffect, useState} from 'react';
import {
    Box, Flex, useToast,
} from '@chakra-ui/react';
import InputComponent from "../../common/InputComponent";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {getAuthorizedHeader} from "../../../common/auth";

interface IPreVisitFormData {
    _id?: string;
    cladding: 'yes' | 'no';
    claddingOrientation?: string[];
    comments?: string;
    semiTrailerAccess: 'yes' | 'no';
    availableMachinery: 'yes' | 'no';
    machinery?: string;
    nearbyGit: 'yes' | 'no';
    gitAddress?: string;
    siteVisitRequirements: string;
    levelPlatform: 'yes' | 'no';
    soilType: string;
    miniExcavator8T: 'yes' | 'no';
    miniExcavator8TReason?: string;
    foundation: 'yes' | 'no';
    additionalComments: string;
};

interface PreVisitFormProps {
    preVisitId: string;
    preVisitFormId?: string;
    callback: () => void;
}

const PreVisitFormComponent = (props: PreVisitFormProps) => {
    const toast = useToast();
    const {t} = useTranslation();
    const [preVisitFormData, setPreVisitFormData] = useState<IPreVisitFormData>(
        {
            _id: '',
            cladding: 'no',
            claddingOrientation: [],
            comments: '',
            semiTrailerAccess: 'no',
            availableMachinery: 'no',
            machinery: '',
            nearbyGit: 'no',
            gitAddress: '',
            siteVisitRequirements: '',
            levelPlatform: 'no',
            soilType: '',
            miniExcavator8T: 'no',
            miniExcavator8TReason: '',
            foundation: 'no',
            additionalComments: '',
        }
    );

    const [isModify, setIsModify] = useState(false);

    useEffect(() => {
        const fetchPreVisitFormData = async () => {
            try {
                if (props.preVisitFormId) {
                    const response = await axios.get(`/api/preVisit/form/${props.preVisitFormId}`, {
                        headers: getAuthorizedHeader()
                    });
                    setPreVisitFormData(response.data);
                    setIsModify(true);
                }
            } catch (error) {
                console.error('Error fetching preVisit form data:', error);
                toast({
                    title: 'Error fetching preVisit form data.',
                    description: `Unable to fetch preVisit form data: ${error.message}`,
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                    position: "top",
                });
            }
        };

        fetchPreVisitFormData();
    }, [props.preVisitFormId, toast]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement> | {
        target: { name: string; value: any }
    }) => {
        const {name, value} = event.target;
        setPreVisitFormData(prevFormData => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleSubmitPreVisit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const dataToSend = { ...preVisitFormData };

        if (dataToSend._id === '')
            delete dataToSend._id;
        if (dataToSend.cladding === 'no') {
            delete dataToSend.claddingOrientation;
            delete dataToSend.comments;
        }
        if (dataToSend.availableMachinery === 'no') {
            delete dataToSend.machinery;
        }
        if (dataToSend.nearbyGit === 'no') {
            delete dataToSend.gitAddress;
        }
        if (dataToSend.miniExcavator8T === 'no') {
            delete dataToSend.miniExcavator8TReason;
        }

        try {
            const response = await axios.put(`/api/preVisit/form/${props.preVisitId}`, dataToSend, {
                headers: getAuthorizedHeader()
            });
            console.log('PreVisit form updated successfully', response.data);
            toast({
                title: 'PreVisit validated.',
                description: "The new preVisit has been successfully validated.",
                status: 'success',
                duration: 3000,
                isClosable: true,
                position: "top",
            });
        } catch (error) {
            console.error('Error updating preVisit form:', error);
            toast({
                title: 'Error validating preVisit.',
                description: `Unable to validate preVisit: ${error.message}`,
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: "top",
            });
        }
        props.callback();
    };

    return (
        <Box>
            <form onSubmit={handleSubmitPreVisit}>
                <InputComponent label={t("bardage ?")} name={"cladding"} type={"selectYesNo"}
                                value={preVisitFormData.cladding}
                                onChange={handleChange} isRequired/>
                {preVisitFormData.cladding === 'yes' &&
                    <Box>
                        <InputComponent
                            label={t("Cladding Orientation")}
                            name="claddingOrientation"
                            type="multiSelectCheckbox"
                            value={preVisitFormData.claddingOrientation}
                            onChange={handleChange}
                            selectOptions={[
                                {value: 'north', label: 'North'},
                                {value: 'south', label: 'South'},
                                {value: 'east', label: 'East'},
                                {value: 'west', label: 'West'}
                            ]}
                            isRequired={false}/>
                        <InputComponent label={t("Commentaires (Porte ...)")} name={"comments"} type={"text"}
                                        value={preVisitFormData.comments}
                                        onChange={handleChange} isRequired={false}/>
                    </Box>
                }
                <InputComponent label={t("accessible par semi-remorque ?")} name={"semiTrailerAccess"}
                                type={"selectYesNo"}
                                value={preVisitFormData.semiTrailerAccess}
                                onChange={handleChange} isRequired/>
                <InputComponent label={t("Machines disponibles ?")} name={"availableMachinery"} type={"selectYesNo"}
                                value={preVisitFormData.availableMachinery}
                                onChange={handleChange} isRequired/>
                {preVisitFormData.availableMachinery === 'yes' &&
                    <InputComponent label={t("Machines disponibles sur le site")} name={"machinery"} type={"text"}
                                    value={preVisitFormData.machinery}
                                    onChange={handleChange} isRequired={false}/>
                }
                <InputComponent label={t("Y a-t-il un gîte à proximité ?")} name={"nearbyGit"} type={"selectYesNo"}
                                value={preVisitFormData.nearbyGit}
                                onChange={handleChange} isRequired/>
                {preVisitFormData.nearbyGit === 'yes' &&
                    <InputComponent label={t("Adresse du gîte")} name={"gitAddress"} type={"text"}
                                    value={preVisitFormData.gitAddress}
                                    onChange={handleChange} isRequired={false}/>
                }
                <InputComponent label={t("Besoins spécifiques pour la visite du site")} name={"siteVisitRequirements"} type={"textArea"}
                                value={preVisitFormData.siteVisitRequirements}
                                onChange={handleChange} isRequired/>
                <InputComponent label={t("Le terrain est-il nivelé ?")} name={"levelPlatform"} type={"selectYesNo"}
                                value={preVisitFormData.levelPlatform}
                                onChange={handleChange} isRequired/>
                <InputComponent label={t("Type de sol")} name={"soilType"} type={"text"}
                                value={preVisitFormData.soilType}
                                onChange={handleChange} isRequired/>

                <InputComponent label={t("Utilisation d'un brise roches ?")} name={"miniExcavator8T"} type={"selectYesNo"}
                                value={preVisitFormData.miniExcavator8T}
                                onChange={handleChange} isRequired/>
                {preVisitFormData.miniExcavator8T === 'yes' &&
                    <InputComponent label={t("Raison du brise roche")} name={"miniExcavator8TReason"} type={"text"}
                                    value={preVisitFormData.miniExcavator8TReason}
                                    onChange={handleChange} isRequired={false}/>
                }
                <InputComponent label={t("Fondation ?")} name={"foundation"} type={"selectYesNo"}
                                value={preVisitFormData.foundation}
                                onChange={handleChange} isRequired/>
                <InputComponent label={t("Vos commentaires")} name={"additionalComments"} type={"textArea"}
                                value={preVisitFormData.additionalComments}
                                onChange={handleChange} isRequired/>
                <Flex justifyContent="flex-end" flexDirection={"row"}>
                    <button type={"submit"} className="learn-more">
                            <span className="circle" aria-hidden="true">
                            <span className="icon arrow"></span>
                            </span>
                        <span className="button-text" style={{marginLeft: '25%'}}>
                            {isModify ? t('Modifier') : t('Validé')}
                        </span>
                    </button>
                </Flex>
            </form>
        </Box>
    );
};

export default PreVisitFormComponent;
