import React from "react";
import {Box, Divider, Heading, Text, useBreakpointValue, useColorModeValue} from "@chakra-ui/react";
import CopyButtonComponent from "../../common/CopyButtonComponent";
import {useTranslation} from "react-i18next";


interface NotesProps {
    notes: string;
}
function NotesComponent(props: NotesProps) {
    const isSmallScreen = useBreakpointValue({base: true, md: false});
    const {t} = useTranslation();

    return (
        <Box padding={5}
             bg={useColorModeValue('white', 'gray.600')}
             border-radius={4}
             rounded="md"
             boxShadow="md"
             marginY={isSmallScreen ? 0 : 5}
             marginLeft={0}
             height={isSmallScreen ? "100%" : "70%"}
        >
            <Heading alignItems="flex-start" fontSize='2xl' color="gray.300">{t("Notes")}<CopyButtonComponent detail={props.notes}/></Heading>
            <Divider marginY={2} width="100%"/>
            <Text>{props.notes}</Text>
        </Box>
    )
}

export default NotesComponent;