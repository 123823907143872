import axios from "axios";
import {getAuthorizedHeader} from "./auth";

function getStatusColor(status: string) {
    if (status === 'COMPLETED') {
        return 'green';
    } else if (status === 'IN PROGRESS') {
        return 'orange';
    } else if (status === 'DELAYED') {
        return 'red';
    }
    return 'yellow';
}

function getButtonStatusValidator(status: string) {
    if (status === 'COMPLETED') {
        return 'restart';
    } else if (status === 'IN PROGRESS') {
        return 'end';
    } else if (status === 'DELAYED') {
        return 'end';
    }
    return 'start';
}

const fetchTask = async (idTask: string, taskName: string) => {
    try {
        const response = await axios.get('/api/' + taskName + '/status/' + idTask, {
            headers: getAuthorizedHeader(),
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching Task:', error);
    }
    return null;
};

export {getStatusColor, getButtonStatusValidator, fetchTask}