import React, {useEffect, useState} from "react";
import {
    Box,
    Center,
    SimpleGrid,
    useColorModeValue,
    useToast
} from "@chakra-ui/react";
import '../../../theme/Button.css'
import {useTranslation} from 'react-i18next';
import InputComponent from "../../common/InputComponent";
import ButtonComponent from "../../common/ButtonComponent";


interface IReferentFormData {
    email: string;
    name: string;
    phone: string;
    role: string;
}

interface IReferentComponentProperties {
    formData: IReferentFormData;
    callback: (data: IReferentFormData) => void;
    onClose: () => void;
}

function NewReferentComponent(props: IReferentComponentProperties) {
    const {t} = useTranslation();
    const [formData, setFormData] = useState<IReferentFormData>(props.formData);
    const [isSmallScreen, setIsSmallScreen] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const toast = useToast();

    useEffect(() => {
        const updateScreenSize = () => {
            setIsSmallScreen(window.innerWidth < 500 || (window.innerWidth > 667 && window.innerWidth < 950));
        };

        updateScreenSize();
        window.addEventListener('resize', updateScreenSize);

        return () => window.removeEventListener('resize', updateScreenSize);
    }, []);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const validateFormData = (): boolean => {
        if (!formData.name || !formData.email || !formData.phone) {
            toast({
                title: "Erreur de validation.",
                description: "Tous les champs sont obligatoires.",
                status: "error",
                duration: 2000,
                isClosable: true,
                position: "top",
            });
        }
        return true;
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (validateFormData()) {
            setIsLoading(true);
            props.callback(formData);
            props.onClose();
        }
    };

    return (
        <Box bg={useColorModeValue('gray.100', 'gray.700')} rounded={"md"} p={5} m={5}>
            <form onSubmit={handleSubmit}>
                <SimpleGrid columns={isSmallScreen ? 1 : 2} spacingX={5}>
                    <InputComponent label={"Name"} name={"name"} type={"text"} value={formData.name}
                                    onChange={handleChange} isRequired/>
                    <InputComponent label={"Phone"} name={"phone"} type={"text"} value={formData.phone}
                                    onChange={handleChange} isRequired/>
                </SimpleGrid>
                <InputComponent label={"Email"} name={"email"} type={"text"} value={formData.email}
                                onChange={handleChange} isRequired/>
                <InputComponent label={"Role"} name={"role"} type={"text"} value={formData.role}
                                onChange={handleChange} isRequired/>
                <Center mt={5}>
                    <ButtonComponent text="Save" callback={() => handleSubmit}/>
                </Center>
            </form>
        </Box>
    );
}

export type {IReferentFormData};
export default NewReferentComponent;