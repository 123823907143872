import React from 'react';
import NavBar from "../components/navigation/NavBarComponent";
import {Box, Center, useBreakpointValue} from "@chakra-ui/react";
import TasksSummaryComponent from "../components/project/TasksSummaryComponent";

function TasksScreen() {
    const isSmallScreen = useBreakpointValue({base: true, md: false});


    return (
        <Box minHeight={window.innerHeight} maxHeight={window.innerHeight}>
            <NavBar/>
            <Box maxHeight={window.innerHeight - 164} minHeight={window.innerHeight - 164} m={0}>
                <Center>
                    <TasksSummaryComponent/>
                </Center>
            </Box>
        </Box>
    );
}

export default TasksScreen;
