import React from 'react';
import {Box, Divider, Flex, Heading, useBreakpointValue, useColorModeValue} from '@chakra-ui/react';
import RegisterComponent, {IRegisterFormData} from './RegisterComponent';
import axios from 'axios';
import {getAuthorizedHeader} from '../../common/auth';
import {useTranslation} from 'react-i18next';
import CopyButtonComponent from "../common/CopyButtonComponent";


function NewEmployee() {
  const isSmallScreen = useBreakpointValue({base: true, md: false});
  const {t} = useTranslation();

  const registerProcess = async (data: IRegisterFormData) => {
    try {
      const response = await axios.post('/api/auth/register', {data}, {
        headers: getAuthorizedHeader(),
      });
      alert('User successful created!');
      window.location.reload();
    } catch (e) {
      console.error(e);
      alert('Unable to create employee!')
    }
  }

  return (
    <Flex bg={useColorModeValue("gray.200", "gray.700")}
          rounded="md"
          boxShadow="md"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          marginLeft={isSmallScreen ? 0 : 5}
          marginTop={isSmallScreen ? 5 : 0}
          textAlign={"center"}
    >
      <Box m={0} p="8" width={"100%"}  overflowY={"auto"} className={"custom-scrollbar"} minHeight={!isSmallScreen ? (window.innerHeight - 174) / 2 : "100%"}
        maxHeight={!isSmallScreen ? (window.innerHeight - 174) / 2 : "100%"}>
          <Heading alignItems="flex-start" fontSize='4xl' color="gray.500">{t("Newemployee")}</Heading>
          <Divider marginY={2} width="100%"/>
        <RegisterComponent callback={data => registerProcess(data)}/>
      </Box>
    </Flex>
  )
}

export default NewEmployee;
