import React from "react";
import {Box, Button, Flex, Text, useColorModeValue} from "@chakra-ui/react";
import {FileDetail} from "../TaskDetailsComponent";
import {DownloadIcon} from "@chakra-ui/icons";
import SupComponent from "../../../common/SupComponent";
import axios from "axios";
import {getAuthorizedHeader} from "../../../../common/auth";
import {deleteFileInTask} from "../../../../common/task";

function PdfPreviewComponent(props: FileDetail) {
    const borderColor = useColorModeValue('#1C2942', '#4285F4');
    const hoverBgColor = useColorModeValue('#A3A3A3', '#A3A3A3');
    const placeholderColor = useColorModeValue('black', 'black');

    return (
        <Box p={4} borderWidth="1px" borderRadius="lg" overflow="hidden" my={4} shadow="md" position="relative">
            <Text fontSize="sm" mb={2}>{props.name}</Text>
            <Box border="1px" borderColor="gray.200" borderRadius="md">
                <iframe src={props.url} style={{width: '100%', height: '500px'}} frameBorder="0"></iframe>
            </Box>
            <Flex justifyContent="flex-end" mt={2} onClick={(e) => e.stopPropagation()}>
                <a href={props.url} download={props.name}>
                    <Button
                        size="sm" mt={2}
                        leftIcon={<DownloadIcon/>}
                        rounded={"2xl"}
                        border={`3px solid ${borderColor}`}
                        _hover={{bg: hoverBgColor}}
                        _focus={{borderColor: borderColor, boxShadow: "none"}}
                        _placeholder={{color: placeholderColor}}
                        width={{base: "80%", sm: "90%"}}
                        mr={{base: 2, sm: 0}}
                        marginTop={2}>Download</Button>
                </a>
                <SupComponent onClickDelete={() => deleteFileInTask(props.task, props.taskId, 'pdf', props.fileId)}
                              callback={props.callback}/>
            </Flex>
        </Box>
    )
}

export default PdfPreviewComponent;
