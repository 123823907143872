import React from 'react';
import {Box, Heading, useColorMode, useBreakpointValue, IconButton, useColorModeValue, Flex} from '@chakra-ui/react';
import { MoonIcon, SunIcon } from '@chakra-ui/icons';
import {useTranslation} from 'react-i18next';

function DarkMode() {
  const { colorMode, toggleColorMode } = useColorMode();
  const isSmallScreen = useBreakpointValue({ base: true, md: false });
  const {t} = useTranslation();

  return (
    <Flex
      bg={useColorModeValue("gray.200", "gray.700")}
      rounded="md"
      boxShadow="md"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      marginLeft={isSmallScreen ? 0 : 5}
      marginTop={isSmallScreen ? 3 : 10}
      textAlign={"center"}
    >
        <Flex mr={6} ml={6} justifyContent="space-between" w="100%" flexDirection={{ base: 'column', md: 'row' }}>
          <Heading
              size={"xl"}
              color={useColorModeValue('gray.300', 'gray.400')}
              lineHeight="tall"
              marginBottom={isSmallScreen ? 3 : 4}
              position="relative"
              transition="transform 0.3s, font-size 0.3s"
              _hover={{
                  transform: 'scale(1.1)',
                  _after: {
                      width: isSmallScreen ? '95%' : '100%',
                  }
              }}
              _after={{
                  content: '""',
                  position: 'absolute',
                  width: '0',
                  height: '1px',
                  bottom: '-5px',
                  left: isSmallScreen ? '2' : '0',
                  backgroundColor: 'black',
                  transition: 'width 0.420s ease-in-out',
              }}>{t("DarkMode")}</Heading>
        <IconButton
          aria-label="Toggle Dark Mode"
          icon={colorMode === 'dark' ? <SunIcon /> : <MoonIcon />}
          onClick={toggleColorMode}
          fontSize={isSmallScreen ? '2xl' : 'lg'}
          colorScheme="teal"
          variant="outline"
          mt={4}
          width={isSmallScreen ? "50%" : "33%"}
        />
      </Flex>
    </Flex>
  );
}

export default DarkMode;
