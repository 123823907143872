import React, { useEffect, useState } from "react";
import {
    Box,
    Heading,
    useBreakpointValue,
    useColorModeValue,
    Flex,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { getAuthorizedHeader } from "../../common/auth";
import TaskDetailsComponent from "./projectDetails/TaskDetailsComponent";
import ProjectModalComponent from "./ProjectModalComponent";
import TaskModalComponent from "./projectDetails/TaskModalComponent";
import SearchComponent from "../common/SearchComponent";

interface TasksSummaryProps {}

function TasksSummaryComponent(props: TasksSummaryProps) {
    const [tasks, setTasks] = useState([]);
    const isSmallScreen = useBreakpointValue({ base: true, md: false });
    const { t } = useTranslation();
    const [selectedTask, setSelectedTask] = useState(null);
    const [selectedProject, setSelectedProject] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    function getTasks() {
        axios.get('/api/tasks/me', {
            headers: getAuthorizedHeader(),
        })
            .then(response => {
                setTasks(response.data);
            })
            .catch(error => {
                console.error('Error fetching tasks:', error);
            });
    }

    async function getProject(projectId: string) {
        setIsLoading(true);
        try {
            const response = await axios.get('/api/project/' + projectId, {
                headers: getAuthorizedHeader(),
            });
            setSelectedProject(response.data);
        } catch (error) {
            console.error("Error fetching Project:", error);
        } finally {
            setIsLoading(false);
        }
    }

    async function getTask(taskId: string, taskName: string) {
        setIsLoading(true);
        try {
            const response = await axios.get('/api/' + taskName + '/' + taskId, {
                headers: getAuthorizedHeader(),
            });
            const taskWithTaskName = {
                ...response.data,
                taskName,
            };
            setSelectedTask(taskWithTaskName);
        } catch (error) {
            console.error("Error fetching Project:", error);
        } finally {
            setIsLoading(false);
        }
    }

    async function handleOpenModal(task: any) {
        setSelectedTask(task);
        await getProject(task.projectId);
        setIsOpen(true);
    }

    function handleCloseModal() {
        setSelectedProject(null);
        setSelectedTask(null);
        setIsLoading(true);
    }

    async function handleCallback(projectId: string, taskId: string, taskName: string) {
        await getTask(taskId, taskName);
        await getProject(projectId);
    }

    useEffect(() => {
        getTasks();
    }, []);

    const filteredTasks = tasks.filter(task =>
        task.projectId.toLowerCase().includes(searchTerm.toLowerCase()) ||
        task.taskName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <Box
            bg={useColorModeValue("gray.200", "gray.700")}
            rounded="md"
            boxShadow="md"
            flexDirection="row"
            justifyContent="space-around"
            alignItems="center"
            textAlign="center"
            width={isSmallScreen ? 'calc(100% - 10px)' : "50%"}
            padding={isSmallScreen ? 3 : 10}
            mr={isSmallScreen ? 3 : 10}
            mb={isSmallScreen ? 3 : 10}
            ml={isSmallScreen ? 3 : 5}
            mt={isSmallScreen ? 0 : 5}
        >
            <Box width="100%">
                <Flex
                    justifyContent="center"
                    w="100%"
                    flexDirection={{base: 'column', md: 'row'}}
                    marginBottom={isSmallScreen ? 3 : 5}
                >
                    <Heading
                        lineHeight="tall"
                        marginBottom={isSmallScreen ? 3 : 0}
                        position="relative"
                        transition="transform 0.3s, font-size 0.3s"
                        _hover={{
                            transform: 'scale(1.1)',
                            _after: {
                                width: isSmallScreen ? '95%' : '100%',
                            }
                        }}
                        _after={{
                            content: '""',
                            position: 'absolute',
                            width: '0',
                            height: '1px',
                            bottom: '-5px',
                            left: isSmallScreen ? '2' : '0',
                            backgroundColor: 'black',
                            transition: 'width 0.420s ease-in-out',
                        }}>
                        {t("Tasks")}
                    </Heading>
                </Flex>
                <SearchComponent value={searchTerm} onChange={(e) => setSearchTerm(e)} />
                <Box
                    overflowY="auto"
                    maxH="70vh"
                    bg={useColorModeValue("white", "gray.800")}
                    boxShadow="md"
                    p={4}
                    mt={5}
                    borderRadius="lg"
                >
                    <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
                        {filteredTasks.map(task => (
                            <Box key={task._id} mb={4} onClick={() => handleOpenModal(task)}>
                                <TaskDetailsComponent
                                    idProject={task.projectId}
                                    idTask={task._id}
                                    task={task.taskName}
                                    status={task.status}
                                    callback={() => getTasks()}
                                    data={false}
                                />
                            </Box>
                        ))}
                    </ul>
                </Box>
            </Box>
            {!isLoading && <ProjectModalComponent selectedProject={selectedProject} handleCloseModal={handleCloseModal}
                                                  callback={() => getTasks()} />}
            {!isLoading && <TaskModalComponent isOpen={isOpen} handleCloseModal={() => setIsOpen(false)}
                                               handleCallback={() => handleCallback(selectedTask.projectId, selectedTask._id, selectedTask.taskName)}
                                               idProject={selectedTask.projectId} idTask={selectedTask._id}
                                               task={selectedTask.taskName} status={selectedTask.status} />}
        </Box>
    );
}

export default TasksSummaryComponent;
