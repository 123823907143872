import React, { useEffect, useState } from "react";
import {
    Box,
    Divider,
    Heading,
    Button,
    Stack,
    Flex,
    useColorModeValue,
    useBreakpointValue,
    Spinner,
    Input,
    InputGroup,
    InputRightElement,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import NavBarComponent from "../navigation/NavBarComponent";
import './MapScreen.css';
import axios from "axios";
import { getAuthorizedHeader } from "../../common/auth";
import {parseDMS} from "../project/projectDetails/MapComponent";

interface Project {
    reference: string;
    latitude: string;
    longitude: string;
    status: string;
}

const getStatusIcon = (status: string) => {
    let color;
    switch (status) {
        case "COMPLETED":
            color = "green";
            break;
        case "IN PROGRESS":
            color = "orange";
            break;
        case "TO DO":
            color = "yellow";
            break;
        case "DELAYED":
            color = "red";
            break;
        default:
            color = "blue";
            break;
    }
    return L.divIcon({
        className: "custom-icon",
        html: `
            <svg width="35" height="35" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path fill="${color}" stroke="black" stroke-width="1"
                      d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/>
            </svg>
        `,
        iconSize: [24, 24],
        iconAnchor: [12, 24],
        popupAnchor: [0, -24],
    });
};

const getPopupClass = (status: string) => {
    switch (status) {
        case "COMPLETED":
            return "popup-COMPLETED";
        case "IN PROGRESS":
            return "popup-in-progress";
        case "TO DO":
            return "popup-TO DO";
        default:
            return "";
    }
};

const MapScreen: React.FC = () => {
    const isSmallScreen = useBreakpointValue({ base: true, md: false });
    const [projects, setProjects] = useState<Project[]>([]);
    const [loading, setLoading] = useState(true);
    const [filteredStatuses, setFilteredStatuses] = useState<string[]>(["COMPLETED", "IN PROGRESS", "TO DO", "DELAYED"]);
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const response = await axios.get('/api/projects/coordinate', { headers: getAuthorizedHeader() });
                setProjects(response.data);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching projects:", error);
                setLoading(false);
            }
        };
        fetchProjects();
    }, []);

    const handleStatusChange = (status: string) => {
        setFilteredStatuses(prevStatuses =>
            prevStatuses.includes(status)
                ? prevStatuses.filter(s => s !== status)
                : [...prevStatuses, status]
        );
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    if (loading) {
        return (
            <Flex align="center" justify="center" height="100vh">
                <Spinner size="xl" />
            </Flex>
        );
    }

    const defaultCenter: [number, number] = projects.length > 0
        ? [parseDMS(projects[0].latitude), parseDMS(projects[0].longitude)]
        : [46.603354, 1.888334];

    const headingColor = useColorModeValue("gray.800", "white");
    const buttonBgColor = useColorModeValue("blue.600", "blue.300");
    const buttonBorderColor = useColorModeValue("blue.600", "blue.300");
    const filterBoxBg = useColorModeValue("white", "gray.800");
    const filterBoxBorder = useColorModeValue("1px solid gray.200", "1px solid gray.600");

    const filteredProjects = projects.filter(project =>
        filteredStatuses.includes(project.status) &&
        project.reference.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <Box>
            <NavBarComponent />
            <Flex align="center" justify="space-between" flexWrap="wrap" p={4}>
                <Heading
                    size={"xl"}
                    color={useColorModeValue('gray.300', 'gray.400')}
                    lineHeight="tall"
                    marginBottom={isSmallScreen ? 3 : 4}
                    position="relative"
                    transition="transform 0.3s, font-size 0.3s"
                    _hover={{
                        transform: 'scale(1.1)',
                        _after: {
                            width: isSmallScreen ? '95%' : '100%',
                        }
                    }}
                    _after={{
                        content: '""',
                        position: 'absolute',
                        width: '0',
                        height: '1px',
                        bottom: '-5px',
                        left: isSmallScreen ? '2' : '0',
                        backgroundColor: 'black',
                        transition: 'width 0.420s ease-in-out',
                    }}>
                    Filter:
                </Heading>
                <Stack direction="row" spacing={4} align="center" flexWrap="wrap" mb={3}>
                    {["COMPLETED", "IN PROGRESS", "TO DO", "DELAYED"].map(status => (
                        <Button
                            key={status}
                            variant="outline"
                            colorScheme="blue"
                            isActive={filteredStatuses.includes(status)}
                            onClick={() => handleStatusChange(status)}
                            bg={filteredStatuses.includes(status) ? buttonBgColor : "transparent"}
                            borderColor={filteredStatuses.includes(status) ? buttonBorderColor : "gray.300"}
                            color={filteredStatuses.includes(status) ? "white" : "gray.600"}
                            _hover={{
                                bg: filteredStatuses.includes(status) ? buttonBgColor : "gray.100",
                            }}
                        >
                            {status.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')}
                        </Button>
                    ))}
                </Stack>
                <InputGroup maxW={isSmallScreen ? "100%" : "300px"} mb={3}>
                    <Input
                        placeholder="Search by reference"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        borderColor="gray.300"
                        _placeholder={{ color: 'gray.500' }}
                        _hover={{ borderColor: 'gray.400' }}
                        _focus={{ borderColor: 'blue.500' }}
                    />
                    <InputRightElement>
                        <SearchIcon color="gray.500" />
                    </InputRightElement>
                </InputGroup>
            </Flex>
            <Divider my={4} />
            <Box
                height="80vh"
                width="100%"
                position="fixed"
            >
                <MapContainer
                    center={defaultCenter}
                    zoom={6}
                    style={{ height: "100%", width: "100%" }}
                >
                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                    {filteredProjects.map((project, index) => (
                        <Marker
                            key={index}
                            position={[parseDMS(project.latitude), parseDMS(project.longitude)]}
                            icon={getStatusIcon(project.status)}
                        >
                            <Popup className={getPopupClass(project.status)}>
                                <strong>{project.reference}</strong> <br />
                                Latitude: {project.latitude} <br />
                                Longitude: {project.longitude}
                            </Popup>
                        </Marker>
                    ))}
                </MapContainer>
            </Box>
        </Box>
    );
}

export default MapScreen;
