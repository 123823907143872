import Cookies from "js-cookie";
import axios from "axios";
import {NavigateFunction, useNavigate} from "react-router-dom";
function getAuthorizedHeader() {
    return {'Authorization': `Bearer ${Cookies.get('token')}`};
}
function logout(navigate: NavigateFunction) {
    axios.post('/api/logout', {}, {headers: getAuthorizedHeader()})
        .catch(reason => console.warn('Unable to logout: ', reason))
        .finally(() => {
            Cookies.remove('token');
            navigate('/login');
        });
}

export {logout, getAuthorizedHeader};