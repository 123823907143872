import React, {useEffect, useState} from "react";
import {
    Box, Button, Flex, Stack, useColorModeValue, useToast
} from "@chakra-ui/react";
import axios from "axios";
import {getAuthorizedHeader} from "../../../common/auth";
import NewProjectForm, {IProjectFormData} from "./NewProjectForm";
import ModalNewClientComponent from "./ModalNewClientComponent";
import ModalNewReferentComponent from "./ModalNewReferentComponent";

interface NewProjectProps {
    callback: () => void;
    handleCloseModal?: () => void;
    projectId?: string;
}

function NewProjectDetails({callback, projectId, handleCloseModal}: NewProjectProps) {
    const toast = useToast();
    const [clients, setClients] = useState<{ value: string; label: string; }[]>([]);
    const [referents, setReferents] = useState<{ value: string; label: string; }[]>([]);
    const [isEditMode, setIsEditMode] = useState<boolean>(!!projectId);

    const [projectFormData, setProjectFormData] = useState<IProjectFormData>({
        reference: '',
        idClient: '',
        idReferent: '',
        begin: new Date(),
        end: new Date(),
        categories: ['Construction'],
        teams: ['Subcontractors', 'Internal'],
        tags: ['Study', 'Order', 'Construction', 'Site Reception', 'Rental', 'Invoicing', 'Adjustments'],
        address: '',
        latitude: '',
        longitude: '',
        notes: '',
        buildingType: '',
    });

    useEffect(() => {
        if (projectId) {
            fetchProjectDetails(projectId);
        }
    }, [projectId]);

    const fetchProjectDetails = async (id: string) => {
        try {
            const response = await axios.get(`/api/project/${id}`, {headers: getAuthorizedHeader()});
            const project = response.data;
            setProjectFormData({
                reference: project.reference,
                idClient: project.idClient,
                idReferent: project.idReferent,
                begin: new Date(project.begin),
                end: new Date(project.end),
                categories: project.categories,
                teams: project.teams,
                tags: project.tags,
                address: project.address,
                latitude: project.latitude,
                longitude: project.longitude,
                notes: project.notes,
                buildingType: project.buildingType,
            });
        } catch (error) {
            console.error("Failed to fetch project details:", error);
            toast({
                title: 'Error fetching project details.',
                description: `Unable to fetch project details: ${error.message}`,
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: "top",
            });
        }
    };

    const handleProjectFormSubmit = async (data: IProjectFormData) => {
        setProjectFormData(data);

        const {
            reference,
            idClient,
            idReferent,
            begin,
            end,
            categories,
            teams,
            tags,
            address,
            latitude,
            longitude,
            notes,
            buildingType
        } = data;

        const requestData = {
            reference,
            idClient,
            idReferent,
            begin,
            end,
            categories,
            teams,
            tags,
            address,
            latitude,
            longitude,
            notes,
            buildingType
        };

        try {
            if (isEditMode && projectId) {
                await axios.put(`/api/project/${projectId}`, requestData, {headers: getAuthorizedHeader()});
                toast({
                    title: 'Project updated.',
                    description: "The project has been successfully updated.",
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                    position: "top",
                });
            } else {
                await axios.post('/api/project', requestData, {headers: getAuthorizedHeader()});
                toast({
                    title: 'Project created.',
                    description: "The new project has been successfully created.",
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                    position: "top",
                });
            }
            callback();
            if (!isEditMode && !projectId) {
                handleCloseModal();
            }
            } catch (error) {
            console.error("Failed to save project:", error);
            toast({
                title: isEditMode ? 'Error updating project.' : 'Error creating project.',
                description: `Unable to save project: ${error.message}`,
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: "top",
            });
        }
    };

    return (
        <Box bg={useColorModeValue('gray.100', 'gray.700')} rounded={"md"} p={5}>
            <Stack direction={{ base: 'column', md: 'row' }} spacing={4} justifyContent={'space-between'}>
                <ModalNewClientComponent setClients={setClients}/>
                <ModalNewReferentComponent setReferents={setReferents}/>
            </Stack>
            <NewProjectForm
                callback={handleProjectFormSubmit}
                formData={projectFormData}
                clients={clients}
                referents={referents}
            />
        </Box>
    );
}

export default NewProjectDetails;