import React, {useEffect, useState} from "react";
import {Box, Button, Flex, Text, useBreakpointValue, useColorModeValue} from '@chakra-ui/react'
import {useTranslation} from "react-i18next";
import {faArchive, faCircleExclamation, faFolderOpen, IconDefinition} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface SelectProps {
    option1: string;
    option1Icon: IconDefinition;
    option2: string;
    option2Icon: IconDefinition;
    option3: string;
    option3Icon: IconDefinition;
    selectedTag: string;
    callback: (data: string) => void;
}

function SelectComponent(props: SelectProps) {
    const {t} = useTranslation();
    const bgColor = useColorModeValue("gray.300", "gray.600");
    const [widthSize, setWidthSize] = useState(window.innerWidth);

    const iconMap = {
        [props.option1]: <FontAwesomeIcon icon={props.option1Icon}/>,
        [props.option2]: <FontAwesomeIcon icon={props.option2Icon}/>,
        [props.option3]: <FontAwesomeIcon icon={props.option3Icon}/>,

    };

    useEffect(() => {
        const handleResize = () => {
            setWidthSize(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const isSmallScreen = useBreakpointValue({base: true, md: false});
    const textFontSize = isSmallScreen ? "2xs" : "md";
    return (
        <Box textAlign={"center"}
             width={"100%"}>
            <Flex bg={useColorModeValue("gray.200", "gray.700")}
                  rounded="md"
                  boxShadow="md"
                  flexDirection={"row"}
                  justifyContent="space-between"
                  margin={"auto"}
                  padding={4}
                  width={widthSize < 1111 ? "100%" : "50%"}>
                {Object.entries(iconMap).map(([option, icon]) => (
                    <Button
                        marginRight={isSmallScreen ? "5" : "0"}
                        key={option}
                        className="buttonStyle"
                        boxShadow="md"
                        _hover={{transform: "scale(1.1)", bg: bgColor}}
                        onClick={() => props.callback(option)}
                        variant={props.selectedTag === option ? "ghost" : "solid"}
                        iconSpacing={0}
                        m={1}
                    >
                        <Box my={2}>
                            {icon}
                            <Text fontSize={textFontSize}>{t(option)}</Text>
                        </Box>
                    </Button>
                ))}
            </Flex>
        </Box>
    );
}

export default SelectComponent;
