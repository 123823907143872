import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import enTranslation from "../assets/locales/en/tranlation.json"
import frTranslation from "../assets/locales/fr/tranlation.json"
import esTranslation from "../assets/locales/es/tranlation.json"

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            fr: {
                translation: frTranslation,
            },
            en: {
                translation: enTranslation,
            },
            es: {
                translation: esTranslation,
            },
        },
        fallbackLng: 'fr',
        detection: {
            order: ['localStorage', 'navigator'],
            caches: ['localStorage'],
        },
        interpolation: {
            escapeValue: false,
        },
    });
export default i18n;
