import React, {useState, useEffect} from 'react';
import {
  Box,
  useBreakpointValue,
} from '@chakra-ui/react';
import EmployeeCard from './EmployeeCard';
import EmployeeDetailsModal from './EmployeeDetailsModal';
import axios from 'axios';
import {getAuthorizedHeader} from '../../common/auth';
import '../../theme/Scrollbar.css'
import NewTeam from "./New_team";
function EmployeesProfileCard() {
  const isSmallScreen = useBreakpointValue({base: true, md: false});
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = React.useState(null);

  const handleOpenModal = (employee: any) => {
    setSelectedEmployee(employee);
  };

  const fetchEmployees = async () => {
    try {
      const response = await axios.get('/api/users', {
        headers: getAuthorizedHeader(),
      });
      setEmployees(response.data);
    } catch (error) {
      console.error('Error fetching employees:', error);
    }
  };
  const handleCloseModal = () => {
    setSelectedEmployee(null);
    fetchEmployees()
  };

  useEffect(() => {
    fetchEmployees();
  }, []);

  return (
    <Box paddingX={10} maxHeight={`calc(100% - 70px)`} overflowY={"auto"} className={"custom-scrollbar"}>
      {employees.map((employee, index) => (
        <React.Fragment key={index}>
          <EmployeeCard employee={employee} onOpen={() => handleOpenModal(employee)}/>
        </React.Fragment>
      ))}

      {selectedEmployee && (
        <EmployeeDetailsModal
          isOpen={Boolean(selectedEmployee)}
          onClose={handleCloseModal}
          employee={selectedEmployee}
        />
      )}
    </Box>
  );
}

export default EmployeesProfileCard;
