import React, { useState } from 'react';
import { Box, Button, useColorModeValue } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

export default function HomeButtonComponent() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(false);
    const bgColor = useColorModeValue("gray.300", "gray.600");

    return (
        <Box textAlign={"center"} mr={isMobile ? 'none' : 8}>
            <Button fontSize="24px" fontWeight="bold"
                    boxShadow="md"
                    _hover={{ transform: "scale(1.1)", bg: bgColor }}
                    onClick={() => navigate('/')}>
                {t('home')}
            </Button>
        </Box>
    );
}
