import React, {useEffect, useState} from 'react';
import {
    Box,
    Heading,
    Input,
    Button,
    FormControl,
    FormLabel,
    SimpleGrid, useBreakpointValue, useColorModeValue, Flex, Select,
} from '@chakra-ui/react';
import InputComponent from "../common/InputComponent";
import ButtonComponent from "../common/ButtonComponent";

interface IRegisterFormData {
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  confirmPassword: string;
  job: string;
  phone: string;
}

interface IRegisterComponentProperties {
  callback: (data: IRegisterFormData) => void;
}

function RegisterComponent(props: IRegisterComponentProperties): React.JSX.Element {
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    job: '',
    phone: '',
    email: '',
    password: '',
    confirmPassword: '',
  } as IRegisterFormData);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 500 || (window.innerWidth > 667 && window.innerWidth < 950));

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 500);
      setIsSmallScreen(window.innerWidth > 667 && window.innerWidth < 950);

    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = event.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };


  const handleRegister = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (formData.password !== formData.confirmPassword) {
      alert('Les mots de passe ne correspondent pas');
      return;
    }

    props.callback(formData);
  };

  return (
    <Box width="100%" margin="auto">
      <form onSubmit={handleRegister}>
        <SimpleGrid columns={isSmallScreen ? 1 : 2} spacingX={isSmallScreen ? 0 : 5}>
            <InputComponent label={"Firstname"} name={"firstname"} type={"text"} value={formData.firstname} onChange={handleChange} isRequired/>
            <InputComponent label={"Lastname"} name={"lastname"} type={"text"} value={formData.lastname} onChange={handleChange} isRequired/>
            <InputComponent label={"Job"} name={"job"} type={"text"} value={formData.job} onChange={handleChange} isRequired/>
            <InputComponent label={"Phone"} name={"phone"} type={"text"} value={formData.phone} onChange={handleChange} isRequired/>
        </SimpleGrid>
          <InputComponent label={"Email"} name={"email"} type={"text"} value={formData.email} onChange={handleChange} isRequired/>
        <SimpleGrid columns={isSmallScreen ? 1 : 2} spacingX={isSmallScreen ? 0 : 5}>
            <InputComponent label={"Password"} name={"password"} type={"password"} value={formData.password} onChange={handleChange} isRequired/>
            <InputComponent label={"Confirm Password"} name={"confirmPassword"} type={"password"} value={formData.confirmPassword} onChange={handleChange} isRequired/>
        </SimpleGrid>

          <Box display={"flex"} justifyContent={"flex-end"} mt={5}>
              <ButtonComponent text={"New Employee"} callback={() => handleRegister}/>
          </Box>

      </form>
    </Box>
  );
}

export type {IRegisterFormData};
export default RegisterComponent;
