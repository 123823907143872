import React from "react";
import {GoogleMap, Marker, useJsApiLoader} from "@react-google-maps/api";
import {Box, Divider, Heading, useColorModeValue} from "@chakra-ui/react";

interface MapProps {
    latitude: string;
    longitude: string;
}

export function parseDMS(input: string): number {
    const parts = input.split(/[^\d+(\,\d+)\d+(\.\d+)?\w]+/);
    const degrees = parseFloat(parts[0]);
    const minutes = parseFloat(parts[1]);
    const seconds = parseFloat(parts[2].replace(/[^0-9.]/g, ''));
    const direction = parts[2].replace(/[^NSEW]/g, '');

    let decimal = degrees + minutes / 60 + seconds / 3600;

    if (direction === "S" || direction === "W") {
        decimal *= -1;
    }
    return decimal;
}

function MapComponent({latitude, longitude}: MapProps) {
    const {isLoaded} = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
        libraries: ["places"],
    });

    const containerStyle = {
        width: '100%',
        height: '300px'
    };

    const lat = parseDMS(latitude);
    const lng = parseDMS(longitude);
    const center = {lat, lng};


    if (!isLoaded) {
        return <div>Loading...</div>;
    }

    return (
        <Box padding={'20px'}
             bg={useColorModeValue('white', 'gray.600')}
             borderRadius={4}
             rounded="md"
             boxShadow="md"
             width={"100%"}
             height={"75%"}>
            <Heading alignItems="flex-start" fontSize='2xl' color="gray.300">Map</Heading>
            <Divider width="100%"/>
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={13}
            >
                <Marker
                    position={center}
                />
            </GoogleMap>
        </Box>
    );
}

export default MapComponent;
