import React, {useEffect, useState} from "react";
import ButtonComponent from "../../common/ButtonComponent";
import {
    Box,
    Divider,
    Flex,
    Heading,
    IconButton,
    Modal, ModalCloseButton,
    ModalContent,
    ModalOverlay, useBreakpointValue,
    useColorModeValue, useToast
} from "@chakra-ui/react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRotateRight} from "@fortawesome/free-solid-svg-icons";
import NewReferentComponent, {IReferentFormData} from "./NewReferentComponent";
import axios from "axios";
import {getAuthorizedHeader} from "../../../common/auth";
import {useTranslation} from "react-i18next";

interface ModalNewReferentProps {
    setReferents: (referentOptions: { value: string; label: string; }[]) => void;
}

function ModalNewReferentComponent(props: ModalNewReferentProps) {
    const {t} = useTranslation();
    const isSmallScreen = useBreakpointValue({base: true, md: false});
    const toast = useToast();
    const [newReferent, setNewReferent] = useState(false);

    const [referentFormData, setReferentFormData] = useState(
        {
            email: '',
            name: '',
            phone: '',
            role: '',
        } as IReferentFormData);

    async function fetchReferents() {
        try {
            const response = await axios.get('/api/referents', {headers: getAuthorizedHeader()});
            const referentOptions = response.data.map((referent: { _id: string; name: string; }) => ({
                value: referent._id,
                label: referent.name,
            }));

            props.setReferents(referentOptions);
        } catch (error) {
            console.warn("Error while fetching referents: " + error);
        }
    }

    const handleOpenModal = () => {
        setNewReferent(true);
    };

    const handleCloseModal = () => {
        setNewReferent(false);
    };

    async function handleReferentFormSubmit(data: IReferentFormData) {
        setReferentFormData(data);
        const {email, name, phone, role} = data;

        try {
            const response = await axios.post('/api/referent', {name, phone, email, role}, {headers: getAuthorizedHeader()});
            if (response.status === 200) {
                toast({
                    title: 'Referent created.',
                    description: "The new referent has been successfully created.",
                    status: 'success',
                    duration: 2000,
                    isClosable: true,
                    position: "top",
                });
            }
        } catch (error) {
            toast({
                title: 'Error creating referent.',
                description: `Unable to create referent: check your data!`,
                status: 'error',
                duration: 2000,
                isClosable: true,
                position: "top",
            });
        } finally {
            await fetchReferents();
        }
    }

    useEffect(() => {
        fetchReferents();
    }, []);

    return (
        <Box>
            <ButtonComponent text={"New Referent"} callback={handleOpenModal}/>
            <Modal isOpen={newReferent}
                   onClose={handleCloseModal}
                   size={isSmallScreen ? "auto" : "xl"}
            >
                <ModalOverlay bg='none'
                              backdropFilter='auto'
                              backdropBlur='2px'/>
                <ModalContent
                >
                    <Flex ml={6} justifyContent="space-between" w="100%" flexDirection={{base: 'column', md: 'row'}}>
                        <Heading
                            size={"xl"}
                            color={useColorModeValue('gray.300', 'gray.400')}
                            lineHeight="tall"
                            marginBottom={isSmallScreen ? 3 : 4}
                            position="relative"
                            transition="transform 0.3s, font-size 0.3s"
                            _hover={{
                                transform: 'scale(1.1)',
                                _after: {
                                    width: isSmallScreen ? '95%' : '100%',
                                }
                            }}
                            _after={{
                                content: '""',
                                position: 'absolute',
                                width: '0',
                                height: '1px',
                                bottom: '-5px',
                                left: isSmallScreen ? '2' : '0',
                                backgroundColor: 'black',
                                transition: 'width 0.420s ease-in-out',
                            }}>
                            {t("NewReferent")}
                        </Heading>
                        <IconButton
                            aria-label="Rotate Right"
                            icon={<FontAwesomeIcon icon={faRotateRight}/>}
                            colorScheme="black"
                            variant="solid"
                            size="md"
                            mb={isSmallScreen ? 3 : 0}
                        />
                    </Flex>
                    <Divider color="gray.300" width="100%"/>
                    <ModalCloseButton
                        position="absolute"
                        top="1rem"
                        right="1rem"
                        bg="transparent"
                        _hover={{bg: 'transparent'}}
                        _active={{bg: 'transparent'}}
                        sx={{
                            '& svg': {
                                transition: 'all 0.2s ease-in-out',
                            },
                            '& svg path': {
                                stroke: 'white',
                                transition: 'stroke 0.3s ease-in-out',
                            },
                            '&:hover svg path': {
                                stroke: 'red',
                                transition: 'stroke 0.5s ease-in-out 0s',
                            },
                        }}/>
                    <NewReferentComponent formData={referentFormData} callback={handleReferentFormSubmit}
                                        onClose={handleCloseModal}/>
                </ModalContent>
            </Modal>
        </Box>
    )
}

export default ModalNewReferentComponent;