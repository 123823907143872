import React from 'react';
import NavBar from '../components/navigation/NavBarComponent';
import Employers from '../components/admin/Employers';
import NewEmployee from '../components/admin/NewEmployee';
import Stat from '../components/admin/Statistique';
import {Flex, Box, useBreakpointValue} from '@chakra-ui/react';

function AdminScreen() {
  const isSmallScreen = useBreakpointValue({base: true, md: false});

  return (
    <Box maxHeight={window.innerHeight}>
      <NavBar/>
      <Flex flexDirection={isSmallScreen ? "column" : "row"} width={"100%"} height={"100%"} padding={isSmallScreen ? 3 : 10}>
          <Employers/>
        <Flex flexDirection="column"
              width={isSmallScreen ? "100%" : "50%"}
        >
          <NewEmployee/>
          <Stat/>
        </Flex>
      </Flex>
    </Box>
  );
}

export default AdminScreen;
