import React from "react";
import {Box, Flex, Text, Progress, useColorModeValue, Badge, useBreakpointValue} from "@chakra-ui/react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHammer, faTruck, faTools, faCheck} from "@fortawesome/free-solid-svg-icons";
import {steps} from "framer-motion";

interface ProgressBarProps {
    progress: number;
}

const ProjetsSteps = [
    {name: "Fabrication", icon: faHammer},
    {name: "Delivery", icon: faTruck},
    {name: "Assembly", icon: faTools},
    {name: "Complete", icon: faCheck},
];

function ProgressBarComponent({progress}: ProgressBarProps) {
    const isSmallScreen = useBreakpointValue({base: true, md: false});
    const bgColor = useColorModeValue("white", "gray.600");
    const boxShadowColor = useColorModeValue("rgba(0, 0, 0, 0.1)", "rgba(255, 255, 255, 0.1)");


    const calculateStepIndex = () => Math.min(Math.floor(progress / (100 / ProjetsSteps.length)), ProjetsSteps.length - 1);

    const getColorScheme = (index: number) => ["red", "orange", "yellow", "green"][index] || "gray";

    const iconSize = isSmallScreen ? "2x" : "2x";
    const badgePaddingX = isSmallScreen ? 3 : 5;
    const badgePaddingY = isSmallScreen ? 1 : 2;
    const textFontSize = isSmallScreen ? "xs" : "md";

    return (
        <Box padding={5} bg={bgColor} borderRadius="4px" boxShadow={`0px 4px 10px ${boxShadowColor}`}>
            <Flex alignItems="center" justifyContent="space-between" mb={4}>
                {ProjetsSteps.map((step, index) => (
                    <Flex key={index} direction="column" align="center" flex="1">
                        <Badge colorScheme={getColorScheme(index)} borderRadius="full" px={badgePaddingX} py={badgePaddingY} display="flex" alignItems="center" justifyContent="center">
                            <FontAwesomeIcon icon={step.icon} size={iconSize} />
                        </Badge>
                        <Text mt={2} fontSize={textFontSize}>{step.name}</Text>
                        {index < ProjetsSteps.length - 1 && (
                            <Box flex="1" borderBottomWidth={2} borderColor={index <= calculateStepIndex() ? getColorScheme(calculateStepIndex()) : "gray.200"} />
                        )}
                    </Flex>
                ))}
            </Flex>
            <Progress value={progress} size="md" colorScheme={getColorScheme(calculateStepIndex())} borderRadius="full" hasStripe isAnimated />
        </Box>
    );
}

export {ProgressBarComponent, ProjetsSteps};