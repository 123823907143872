import React from 'react';
import {
    Box,
    Heading,
    Flex,
    Button,
    useColorMode,
    useBreakpointValue, useColorModeValue,
    SimpleGrid,
} from '@chakra-ui/react';

function ColorPalette() {
    const { colorMode } = useColorMode();
    const isSmallScreen = useBreakpointValue({ base: true, md: false });

    const colors = colorMode === 'light' ? [
        'red.300', 'orange.300', 'yellow.300', 'green.300',
        'teal.300', 'blue.300', 'cyan.300', 'purple.300', 'pink.300',
    ] : [
        'red.500', 'orange.500', 'yellow.500', 'green.500',
        'teal.500', 'blue.500', 'cyan.500', 'purple.500', 'pink.500',
    ];

    return (
        <Box
            bg={useColorModeValue("gray.200", "gray.700")}
            rounded="md"
            boxShadow="md"
            p="4"
            m={0}
            mt={isSmallScreen ? 3 : 10}
            ml={isSmallScreen ? 0 : 5}
            textAlign="center"
        >
            <Flex mr={6} ml={6} justifyContent="center" w="100%" flexDirection={{ base: 'column', md: 'row' }}>
                <Heading
                    textAlign={"center"}
                    size={"xl"}
                    color={useColorModeValue('gray.300', 'gray.400')}
                    lineHeight="tall"
                    marginBottom={isSmallScreen ? 3 : 4}
                    position="relative"
                    transition="transform 0.3s, font-size 0.3s"
                    _hover={{
                        transform: 'scale(1.1)',
                        _after: {
                            width: isSmallScreen ? '95%' : '100%',
                        }
                    }}
                    _after={{
                        content: '""',
                        position: 'absolute',
                        width: '0',
                        height: '1px',
                        bottom: '-5px',
                        left: isSmallScreen ? '2' : '0',
                        backgroundColor: 'black',
                        transition: 'width 0.420s ease-in-out',
                    }}>
                    Choisissez une couleur
                </Heading>
            </Flex>
            <SimpleGrid columns={isSmallScreen ? 3 : 5} spacing={2} marginTop="4">
                {colors.map((color, index) => (
                    <Button
                        key={index}
                        bg={color}
                        size={isSmallScreen ? "sm" : "md"}
                        _hover={{ filter: 'brightness(80%)' }}
                        onClick={() => console.log(`Couleur sélectionnée : ${color}`)}
                    />
                ))}
            </SimpleGrid>
        </Box>
    );
}

export default ColorPalette;
