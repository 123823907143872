import React from "react";
import {Box, Button, Center, Flex, Image, Text, useColorModeValue} from "@chakra-ui/react";
import {FileDetail} from "../TaskDetailsComponent";
import {DownloadIcon} from "@chakra-ui/icons";
import SupComponent from "../../../common/SupComponent";
import {deleteFileInTask} from "../../../../common/task";

function ImagePreviewComponent(props: FileDetail) {
    const borderColor = useColorModeValue('#1C2942', '#4285F4');
    const hoverBgColor = useColorModeValue('#A3A3A3', '#A3A3A3');
    const placeholderColor = useColorModeValue('black', 'black');

    return (
        <Box p={2} borderWidth="1px" borderRadius="lg" overflow="hidden" width={'250px'} height={'250px'}>

            <Center flexDirection='column'>
                <Text fontSize="sm" fontWeight="semibold" mb={2} textAlign="center">{props.name}</Text>
                <Image borderRadius="lg" src={props.url} alt={`${props.name}`} height='150px' objectFit="cover"/>
                <Flex justifyContent="flex-end" flexDirection={"row"}>
                    <a href={props.url} download={`${props.name}`} style={{textDecoration: 'none'}}>
                        <Button
                            size="sm" mt={2}
                            leftIcon={<DownloadIcon/>}
                            rounded={"2xl"}
                            border={`3px solid ${borderColor}`}
                            _hover={{bg: hoverBgColor}}
                            _focus={{borderColor: borderColor, boxShadow: "none"}}
                            _placeholder={{color: placeholderColor}}
                            width={{base: "80%", sm: "90%"}}
                            mr={{base: 2, sm: 0}}
                            marginTop={2}>Download</Button>
                    </a>
                    <SupComponent onClickDelete={() => deleteFileInTask(props.task, props.taskId, 'image', props.fileId)} callback={props.callback}/>
                </Flex>
            </Center>
        </Box>
    )
}

export default ImagePreviewComponent;