import React, {useEffect, useState} from "react";
import {
    Box,
    Flex, Heading, IconButton,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay, Text,
    useBreakpointValue, useColorModeValue
} from "@chakra-ui/react";
import {getStatusColor} from "../../common/status";
import ProjectDetailsComponent, {IProject} from "./ProjectDetailsComponent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRotateRight} from "@fortawesome/free-solid-svg-icons";
import ModifyProjectComponent from "./newProject/ModifyProjectComponent";
import axios from "axios";
import {getAuthorizedHeader} from "../../common/auth";
import {checkIfUserIsLoggedIn} from "../LoginComponent";
import {useNavigate} from "react-router-dom";

interface ProjectModalProps {
    selectedProject: any;
    handleCloseModal: () => void;
    callback: () => void;
}

function ProjectModalComponent({selectedProject, callback, handleCloseModal}: ProjectModalProps) {
    const isLoggedIn = checkIfUserIsLoggedIn();
    const isSmallScreen = useBreakpointValue({base: true, md: false});
    const [project, setProject] = useState({} as IProject);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);

    async function getProject() {
        setIsLoading(true);
        try {
            const response = await axios.get('/api/project/' + selectedProject?._id, {
                headers: getAuthorizedHeader(),
            });
            setProject(response.data);
        } catch (error) {
            console.error("Error fetching Project:", error);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (isLoggedIn) {
            getProject();
        } else {
            navigate('/login');
        }
    }, [isLoggedIn, navigate, selectedProject?._id]);

    function handleCallback() {
        getProject();
        callback();
    }

    return (
        <Modal isOpen={!!selectedProject}
               onClose={handleCloseModal}
               size={isSmallScreen ? "auto" : "6xl"}
        >
            <ModalOverlay bg='none'
                          backdropFilter='auto'
                          backdropBlur='2px'/>

            <ModalContent
                padding={isSmallScreen ? 1 : 5}
                border="2px"
                borderColor={getStatusColor(selectedProject?.status)}
                borderRadius="md"
            >
                <Flex ml={6} justifyContent="space-between" alignItems="center" w="100%"
                      flexDirection={{base: 'column', md: 'row'}}>
                    <Heading
                        size={"xl"}
                        color={useColorModeValue('gray.300', 'gray.400')}
                        lineHeight="tall"
                        marginBottom={isSmallScreen ? 3 : 4}
                        position="relative"
                        transition="transform 0.3s, font-size 0.3s"
                        _hover={{
                            transform: 'scale(1.1)',
                            _after: {
                                width: isSmallScreen ? '95%' : '100%',
                            }
                        }}
                        _after={{
                            content: '""',
                            position: 'absolute',
                            width: '0',
                            height: '1px',
                            bottom: '-5px',
                            left: isSmallScreen ? '2' : '0',
                            backgroundColor: 'black',
                            transition: 'width 0.420s ease-in-out',
                        }}>
                        {selectedProject?.reference}
                    </Heading>
                    <Flex alignItems="center" ml={isSmallScreen ? 0 : 5} mt={isSmallScreen ? 2 : 0}>
                        <ModifyProjectComponent callback={handleCallback} projectId={selectedProject?._id}/>
                        <IconButton
                            aria-label="Rotate Right"
                            icon={<FontAwesomeIcon icon={faRotateRight}/>}
                            colorScheme="black"
                            variant="solid"
                            size="md"
                            ml={2}
                            mr={"2"}
                        />
                    </Flex>
                </Flex>
                <ModalCloseButton
                    position="absolute"
                    top="1rem"
                    right="1rem"
                    bg="transparent"
                    _hover={{bg: 'transparent'}}
                    _active={{bg: 'transparent'}}
                    sx={{
                        '& svg': {
                            transition: 'all 0.2s ease-in-out',
                        },
                        '& svg path': {
                            stroke: 'white',
                            transition: 'stroke 0.3s ease-in-out',
                        },
                        '&:hover svg path': {
                            stroke: 'red',
                            transition: 'stroke 0.5s ease-in-out 0s',
                        },
                    }}/>
                <ModalBody>
                    {isLoading && <Box padding={5} bg={useColorModeValue("gray.200", "gray.700")}>
                        <Text>Loading project details...</Text>
                    </Box>}
                    {!isLoading && <ProjectDetailsComponent idProject={selectedProject?._id} callback={callback}
                                                            project={project}/>}
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default ProjectModalComponent;
