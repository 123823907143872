import React from "react";
import { Badge, Flex, HStack, useBreakpointValue } from "@chakra-ui/react";
import { getStatusColor } from "../../../../common/status";

interface TaskCommonDataProps {
    daysLefts: number;
    beginDate: string;
    endDate: string;
    status: string;
}

function TaskCommonDataComponent(props: TaskCommonDataProps) {
    const isSmallScreen = useBreakpointValue({ base: true, md: false });

    return (
        <Flex
            my="2"
            wrap="wrap"
            direction="column"
        >
            <HStack spacing={2} wrap="wrap"> // Allows badges to wrap onto the next line
                <Badge colorScheme="blue" size={isSmallScreen ? "sm" : "md"}>
                    {props.daysLefts} jours
                </Badge>
                <Badge colorScheme="green" size={isSmallScreen ? "sm" : "md"}>
                    {props.beginDate}
                </Badge>
                <Badge colorScheme="green" size={isSmallScreen ? "sm" : "md"}>
                    {props.endDate}
                </Badge>
                <Badge
                    colorScheme={getStatusColor(props.status)}
                    size={isSmallScreen ? "sm" : "md"}
                >
                    {props.status}
                </Badge>
            </HStack>
        </Flex>
    )
}

export default TaskCommonDataComponent;
