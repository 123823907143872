import React, {useEffect, useState} from 'react';
import {Box, Flex, Tag, TagLabel, Text, WrapItem, Image, useColorModeValue} from '@chakra-ui/react';

interface EmployeeCardProps {
    employee: {
        _id: string;
        firstname: string;
        lastname: string;
        profilePicture: string;
        job: string;
    };
    onOpen: () => void;
}

function EmployeeCard(props: EmployeeCardProps) {
    const {employee, onOpen} = props;
    const profilePictureUrl = process.env.REACT_APP_API_URL as string;
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 420);
    const borderColor = useColorModeValue('white !important', '#111C44 !important');
    const bgColor = useColorModeValue('#B6D8F2', '#2D4B73');

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 420);

        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const generateRandomColor = () => {
        const excludedColor = "#B6D8F2";

        let randomColor;
        do {
            randomColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
        } while (randomColor === excludedColor);

        return randomColor;
    };
    return (
        <Flex
            bg={bgColor}
            rounded="md"
            boxShadow="md"
            flexDirection={isSmallScreen ? "column" : "row"}
            justifyContent="space-between"
            align="center"
            padding="4"
            onClick={onOpen}
            cursor="pointer"
            marginY={4}
            width="100%"
        >
            <WrapItem>
                <Flex
                    h="100px"
                    w="100px"
                    rounded="full"
                    overflow="hidden"
                    onClick={onOpen}
                    justifyContent="center"
                    align="center"
                    mx="auto"
                    mb={isSmallScreen ? 3 : 0}
                    border="4px solid"
                    borderColor={borderColor}
                    borderRadius="full"
                    position="relative"
                    bg={`#${Math.floor(Math.random() * 16777215).toString(16)}`}
                >
                    {employee.profilePicture ? (
                        <Image
                            src={profilePictureUrl + '/api/user/picture/' + employee.profilePicture}
                            alt={`Profile of ${employee.firstname} ${employee.lastname}`}
                            objectFit="cover"
                            w="100%"
                            h="100%"
                        />
                    ) : (
                        <Text
                            fontSize="lg"
                            fontWeight="bold"
                            color="white"
                            textAlign="center"
                            lineHeight="50px"
                        >
                            {`${employee.firstname[0].toUpperCase()} ${employee.lastname[0].toUpperCase()}`}
                        </Text>
                    )}
                </Flex>
            </WrapItem>
            <Box>
                <Text fontSize={{base: 'xl', md: '2xl'}} fontWeight="bold" mb="2" display="flex"
                      justifyContent="flex-end">
                    {`${employee.firstname} ${employee.lastname}`}
                </Text>
                <Box width={"100%"} display="flex" justifyContent="flex-end">
                    <Tag size="lg" colorScheme="red" borderRadius="full" ml={'auto'} mr={isSmallScreen ? "auto" : 0}>
                        <TagLabel>{employee.job}</TagLabel>
                    </Tag>
                </Box>
            </Box>
        </Flex>
    );
}

export default EmployeeCard;
