import React, {useState, useEffect} from 'react';
import {Text, Flex, Box, Heading, Highlight, useBreakpointValue, Button, useColorModeValue} from '@chakra-ui/react';
import {checkIfUserIsLoggedIn} from '../LoginComponent';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';
import {getAuthorizedHeader, logout} from '../../common/auth';
import ProfileComponent from './ProfileComponent';
import {FaDoorOpen, FaKey} from 'react-icons/fa';
import {useTranslation} from 'react-i18next';
import ModifyPasswordComponent from "./ModifyPasswordComponent";


export interface IProfile {
    email: string;
    lastname: string;
    firstname: string;
    job: string;
    phone: string;
    passwordLength: number;
    profilePicture?: string;
}

function Profile() {
    const {t} = useTranslation();
    const isSmallScreen = useBreakpointValue({base: true, md: false});
    const isLoggedIn = checkIfUserIsLoggedIn();
    const navigate = useNavigate();
    const [profile, setProfile] = useState({} as IProfile);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        async function getProfile() {
            try {
                const response = await axios.get('/api/me', {
                    headers: getAuthorizedHeader(),
                });
                setProfile(response.data);
            } catch (error) {
                console.error("Error fetching profile:", error);
            }
        }

        if (isLoggedIn) {
            getProfile();
        } else {
            navigate('/login');
        }
    }, [isLoggedIn, navigate]);

    function handleCloseModal() {
        setIsOpen(false);
    }

    return (
        <Flex
            bg={useColorModeValue("gray.200", "gray.700")}
            rounded="md"
            boxShadow="md"
            width={isSmallScreen ? "100%" : "50%"}
            height={"100%"}
            marginRight={isSmallScreen ? 0 : 5}
        >
            <Box p={isSmallScreen ? 2 : 8} width={"100%"} height={"100%"} minHeight={window.innerHeight - 174}
            >
                <Flex justifyContent="center" w="100%" flexDirection={{base: 'column', md: 'row'}}>
                    <Heading
                        textAlign={"center"}
                        size={"xl"}
                        color={useColorModeValue('gray.300', 'gray.400')}
                        lineHeight="tall"
                        marginBottom={isSmallScreen ? 3 : 4}
                        position="relative"
                        transition="transform 0.3s, font-size 0.3s"
                        _hover={{
                            transform: 'scale(1.1)',
                            _after: {
                                width: isSmallScreen ? '95%' : '100%',
                            }
                        }}
                        _after={{
                            content: '""',
                            position: 'absolute',
                            width: '0',
                            height: '1px',
                            bottom: '-5px',
                            left: isSmallScreen ? '2' : '0',
                            backgroundColor: 'black',
                            transition: 'width 0.420s ease-in-out',
                        }}>
                        {t("Profile")}
                    </Heading>
                </Flex>
                <ProfileComponent user={profile}/>
                <Box width={"100%"}
                     marginTop={isSmallScreen ? 0 : 10}
                     display="flex"
                     justifyContent="center"
                >
                    <Button
                        leftIcon={<FaKey/>}
                        onClick={() => setIsOpen(true)}
                        className="buttonStyle"
                        colorScheme="purple"
                        size="sm"
                        mr={isSmallScreen ? 3 : 10}
                    >
                        Modify Password
                    </Button>
                    <Button
                        leftIcon={<FaDoorOpen/>}
                        onClick={() => logout(navigate)}
                        className="buttonStyle"
                        colorScheme="red"
                        size="sm"
                    >
                        Logout
                    </Button>
                </Box>
            </Box>
            <ModifyPasswordComponent isOpen={isOpen} handleCloseModal={handleCloseModal} userId={"me"}/>
        </Flex>
    );
}

export default Profile;
