import React, {useEffect, useState} from "react";
import {Box, useBreakpointValue} from "@chakra-ui/react";
import NavBar from "../components/navigation/NavBarComponent";
import SelectComponent from "../components/common/SelectComponent";
import ProjectsComponent from "../components/project/ProjectsComponent";
import axios from "axios";
import {getAuthorizedHeader} from "../common/auth";
import {faArchive, faCircleExclamation, faFolderOpen} from "@fortawesome/free-solid-svg-icons";

function getProjectRoute(tag: string) {
    if (tag === 'COMPLETED')
        return '/api/projects/completed';
    else if (tag === 'IN PROGRESS')
        return '/api/projects';
    else
        return '/api/projects/delayed';
}
export async function fetchProjects(tag: string) {
    try {
        const response = await axios.get(getProjectRoute(tag), {
            headers: getAuthorizedHeader(),
        });
        return (response.data);
    } catch (error) {
        console.error('Error fetching Projects:', error);
    }
}
function ProjectScreen() {
    const [selectedTag, setSelectedTag] = useState('IN PROGRESS');
    const [projects, setProjects] = useState([]);

    const isSmallScreen = useBreakpointValue({base: true, md: false});



    function handleTagChange(newTag: string) {
        setSelectedTag(newTag);
        fetchProjects(newTag).then(r => setProjects(r));
    }

    useEffect(() => {
        fetchProjects(selectedTag).then(r => setProjects(r));
    }, [selectedTag]);

    return (
        <Box width={"100%"}>
            <NavBar/>
            <Box width={"100%"} height={"100%"} padding={{base: 3, md: 10}}>
                <SelectComponent selectedTag={selectedTag} callback={handleTagChange} option1={"COMPLETED"}
                                 option2={"IN PROGRESS"} option3={"DELAYED"} option1Icon={faArchive}
                                 option2Icon={faFolderOpen} option3Icon={faCircleExclamation}/>
                <ProjectsComponent selectedTag={selectedTag} projects={projects} callback={() => fetchProjects(selectedTag).then(r => setProjects(r))}/>
            </Box>
        </Box>
    );
}

export default ProjectScreen;
