import React, { useState } from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box,
    Text,
    useColorModeValue,
    Heading,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    PopoverArrow,
    Flex,
    Avatar,
    AvatarGroup,
    Button,
} from '@chakra-ui/react';
import { differenceInDays, addDays, format, isWithinInterval } from 'date-fns';
import CreateTaskModal from './CreateTaskModal';
import ButtonComponent from '../../common/ButtonComponent';

interface Task {
    name: string;
    startDate: string;
    endDate: string;
    assignedTo: {
        type: 'person' | 'team';
        name: string;
        members?: string[];
    };
}

interface Project {
    title: string;
    startDate: string;
    endDate: string;
    tasks: Task[];
}

const taskColors: Record<string, string> = {
    Massonerie: "yellow.500",
    Montage: "yellow.500",
};

const GanttTasks: React.FC = () => {
    const [validatedTasks, setValidatedTasks] = useState<{ [key: string]: boolean }>({});
    const [isCreateTaskModalOpen, setCreateTaskModalOpen] = useState(false);

    const [projects, setProjects] = useState<Project[]>([
        {
            title: 'Project Alpha',
            startDate: '2024-09-01',
            endDate: '2024-10-07',
            tasks: [
                {
                    name: 'Massonerie',
                    startDate: '2024-09-01',
                    endDate: '2024-09-03',
                    assignedTo: {
                        type: 'team',
                        name: 'Equipe Massonerie',
                        members: ['Jean Dupont', 'Marc'],
                    },
                },
                {
                    name: 'Montage',
                    startDate: '2024-09-24',
                    endDate: '2024-10-07',
                    assignedTo: {
                        type: 'team',
                        name: 'Equipe Montage',
                        members: ['Lucie', 'Julien'],
                    },
                },
            ],
        },
    ]);

    const generateDays = (startDate: string, endDate: string) => {
        const start = new Date(startDate);
        const end = new Date(endDate);
        const numberOfDays = differenceInDays(end, start) + 1;
        const days = [];
        for (let i = 0; i < numberOfDays; i++) {
            const currentDate = addDays(start, i);
            days.push({
                date: format(currentDate, 'dd/MM/yyyy'),
                dayStart: currentDate,
            });
        }
        return days;
    };

    const handleValidateTask = (taskName: string) => {
        setValidatedTasks((prev) => ({ ...prev, [taskName]: !prev[taskName] }));
    };

    const handleCreateTask = (newTask: Task) => {
        const updatedProjects = [...projects];
        updatedProjects[0].tasks.push(newTask);
        setProjects(updatedProjects);
        setCreateTaskModalOpen(false);
    };

    return (
        <Box width="100%" marginTop={2} bg={"none"}>
            <Accordion mx={0} mb={0} allowMultiple width="100%" bg={useColorModeValue('white', 'gray.600')} borderRadius={4} rounded="md" boxShadow="md">
                <AccordionItem>
                    <h2>
                        <AccordionButton>
                            <Box flex="1" textAlign="left" fontWeight="bold">
                                <Heading alignItems="flex-start" fontSize='2xl' color="gray.300">Titre</Heading>
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                        <Box width="100%" overflowX="auto">
                            <ButtonComponent
                                text="Add New Task"
                                callback={() => setCreateTaskModalOpen(true)}
                            />
                            <CreateTaskModal
                                isOpen={isCreateTaskModalOpen}
                                onClose={() => setCreateTaskModalOpen(false)}
                                handleCreateTask={handleCreateTask}
                            />
                            <TableContainer>
                                <Table variant="simple">
                                    <Thead>
                                        <Tr>
                                            <Th>Project</Th>
                                            <Th>Date début</Th>
                                            {projects.length > 0 && generateDays(projects[0].startDate, projects[0].endDate).map((day, index) => (
                                                <Th key={index} textAlign="center">
                                                </Th>
                                            ))}
                                            <Th>Date fin</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {projects.map((project, projectIndex) => (
                                            <React.Fragment key={projectIndex}>
                                                <Tr bg="gray.100">
                                                    <Td fontWeight="bold" paddingY="1rem">{project.title}</Td>
                                                    <Td paddingY="1rem">{project.startDate}</Td>
                                                    {generateDays(project.startDate, project.endDate).map((day, index) => (
                                                        <Td key={index} textAlign="center">
                                                            <Text fontSize="sm" color="gray.500">{day.date}</Text>
                                                        </Td>
                                                    ))}
                                                    <Td paddingY="1rem">{project.endDate}</Td>
                                                </Tr>
                                                {project.tasks.map((task, taskIndex) => {
                                                    const days = generateDays(project.startDate, project.endDate);
                                                    const taskStart = new Date(task.startDate);
                                                    const taskEnd = new Date(task.endDate);
                                                    const startDayIndex = days.findIndex(day => isWithinInterval(taskStart, { start: day.dayStart, end: day.dayStart }));
                                                    const endDayIndex = days.findIndex(day => isWithinInterval(taskEnd, { start: day.dayStart, end: day.dayStart }));
                                                    const colSpan = endDayIndex - startDayIndex + 1;
                                                    const isTaskValidated = validatedTasks[task.name];
                                                    const taskColor = isTaskValidated ? "green.300" : taskColors[task.name] || "blue.300";

                                                    return (
                                                        <Tr key={taskIndex} bg={taskIndex % 2 === 0 ? "white" : "gray.50"}>
                                                            <Td paddingY="0.75rem" colSpan={2}>
                                                                <Text fontWeight="medium">{task.name}</Text>
                                                                <Text fontSize="sm" color="gray.500">{task.startDate} - {task.endDate}</Text>
                                                            </Td>
                                                            {days.map((day, index) => (
                                                                index === startDayIndex ? (
                                                                    <Td key={index} textAlign="center" colSpan={colSpan}>
                                                                        <Popover>
                                                                            <PopoverTrigger>
                                                                                <Box
                                                                                    bg={taskColor}
                                                                                    borderRadius="md"
                                                                                    padding="0.5rem"
                                                                                    color="white"
                                                                                    fontWeight="bold"
                                                                                    cursor="pointer"
                                                                                    _hover={{ bg: isTaskValidated ? "green.500" : `${taskColor.replace('.300', '.400')}` }}
                                                                                >
                                                                                    {task.name}
                                                                                </Box>
                                                                            </PopoverTrigger>
                                                                            <PopoverContent>
                                                                                <PopoverArrow />
                                                                                <PopoverBody p={6} bg="gray.50" borderRadius="md" boxShadow="lg">
                                                                                    {(() => {
                                                                                        const taskStart = new Date(task.startDate);
                                                                                        const taskEnd = new Date(task.endDate);
                                                                                        const taskDuration = differenceInDays(taskEnd, taskStart) + 1;

                                                                                        return (
                                                                                            <>
                                                                                                <Box mb={4}>
                                                                                                    <Text fontSize="lg" fontWeight="bold" color="blue.600" mb={2}>
                                                                                                        {task.name}
                                                                                                    </Text>
                                                                                                </Box>

                                                                                                <Box mb={4}>
                                                                                                    <Flex justify="space-between">
                                                                                                        <Text fontSize="sm" fontWeight="medium" color="gray.600">
                                                                                                            <strong>Début:</strong> {task.startDate}
                                                                                                        </Text>
                                                                                                        <Text fontSize="sm" fontWeight="medium" color="gray.600">
                                                                                                            <strong>Fin:</strong> {task.endDate}
                                                                                                        </Text>
                                                                                                    </Flex>
                                                                                                </Box>

                                                                                                <Box mb={6}>
                                                                                                    <Text fontSize="sm" fontWeight="medium" color="gray.600" mb={2}>
                                                                                                        <strong>Assigné à:</strong>
                                                                                                    </Text>
                                                                                                    {task.assignedTo.type === 'person' ? (
                                                                                                        <Flex align="center">
                                                                                                            <Avatar size="sm" name={task.assignedTo.name} mr={2} />
                                                                                                            <Text fontSize="sm" fontWeight="medium" color="gray.700">{task.assignedTo.name}</Text>
                                                                                                        </Flex>
                                                                                                    ) : (
                                                                                                        <AvatarGroup size="sm" max={3}>
                                                                                                            {task.assignedTo.members?.map((member, i) => (
                                                                                                                <Avatar key={i} name={member} />
                                                                                                            ))}
                                                                                                        </AvatarGroup>
                                                                                                    )}
                                                                                                </Box>

                                                                                                <Flex justify="space-between" align="center" mt={4} pt={4} borderTop="1px solid" borderColor="gray.200">
                                                                                                    <Box>
                                                                                                        <Text fontSize="sm" fontWeight="medium" color="gray.600">
                                                                                                            <strong>Durée:</strong> {taskDuration} jours
                                                                                                        </Text>
                                                                                                    </Box>
                                                                                                    <Button
                                                                                                        size="sm"
                                                                                                        colorScheme={isTaskValidated ? "green" : "yellow"}
                                                                                                        onClick={() => handleValidateTask(task.name)}
                                                                                                        boxShadow="sm"
                                                                                                        _hover={{ bg: isTaskValidated ? 'green.500' : 'blue.500' }}
                                                                                                    >
                                                                                                        {isTaskValidated ? "Terminé" : "Valider"}
                                                                                                    </Button>
                                                                                                </Flex>
                                                                                            </>
                                                                                        );
                                                                                    })()}
                                                                                </PopoverBody>
                                                                            </PopoverContent>
                                                                        </Popover>
                                                                    </Td>
                                                                ) : index > startDayIndex && index <= endDayIndex ? null : (
                                                                    index === startDayIndex + 3 ? (
                                                                        <Td key={index} textAlign="center" colSpan={colSpan}>
                                                                            <Text fontSize="sm" color="gray.400">...</Text>
                                                                        </Td>
                                                                    ) : (
                                                                        <Td key={index}></Td>
                                                                    )
                                                                )
                                                            ))}
                                                        </Tr>
                                                    );
                                                })}
                                            </React.Fragment>
                                        ))}
                                    </Tbody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
        </Box>
    );
};

export default GanttTasks;
