import {RouterProvider} from "react-router-dom";
import {I18nextProvider} from "react-i18next";
import Routes from "./Routes";
import i18n from "../components/i18n"
import React from "react";
import {Box} from '@chakra-ui/react';

const App = (): JSX.Element => {
    return (
        <I18nextProvider i18n={i18n}>
            <Box className="container">
                <RouterProvider router={Routes}/>
            </Box>
        </I18nextProvider>
    );
}
export default App;
