import React, {useEffect} from "react";
import {
    Box,
    Flex,
    useBreakpointValue,
} from "@chakra-ui/react";
import {useNavigate} from "react-router-dom";
import {checkIfUserIsLoggedIn} from "../components/LoginComponent";
import NavBar from "../components/navigation/NavBarComponent";
import ManagerBarComponent from "../components/ManagerBarComponent";
import ProjectsSummaryComponent from "../components/project/ProjectsSummaryComponent";
import TasksSummaryComponent from "../components/project/TasksSummaryComponent";


function HomeScreen() {
    const isLoggedIn = checkIfUserIsLoggedIn();
    const navigate = useNavigate();
    const isSmallScreen = useBreakpointValue({base: true, md: false});

    useEffect(() => {
        if (!isLoggedIn) {
            navigate("/login");
        }
    }, [isLoggedIn, navigate]);

    return (
        <Box minHeight={window.innerHeight} maxHeight={window.innerHeight}>
            <NavBar/>
            <Box maxHeight={window.innerHeight - 164} minHeight={window.innerHeight - 164} m={0}>
                <Box marginTop={5}>
                    <ManagerBarComponent/>
                </Box>
                <Box display={'flex'} flexDirection={isSmallScreen ? "column" : "row"} height={"75%"}>
                    <ProjectsSummaryComponent/>
                    <TasksSummaryComponent/>
                </Box>
            </Box>
        </Box>
    );
}

export default HomeScreen;
