import React, { useState } from 'react';
import {
    Box,
    Button,
    Input,
    FormControl,
    FormLabel,
    useToast,
    Text, InputRightElement, IconButton, InputGroup,
} from '@chakra-ui/react';
import Cookies from "js-cookie";
import {MdOutlineRemoveRedEye} from "react-icons/md";
import {RiEyeCloseLine} from "react-icons/ri";
import { createUseStyles } from 'react-jss';
import {useNavigate} from "react-router-dom";

const useStyles = createUseStyles({
    shake: {
        animation: '$shake 0.82s cubic-bezier(.36,.07,.19,.97) both',
        'animation-iteration-count': 'infinite',
    },
    '@keyframes shake': {
        '10%, 90%': {
            transform: 'translate3d(-1px, 0, 0)',
        },
        '20%, 80%': {
            transform: 'translate3d(2px, 0, 0)',
        },
        '30%, 50%, 70%': {
            transform: 'translate3d(-4px, 0, 0)',
        },
        '40%, 60%': {
            transform: 'translate3d(4px, 0, 0)',
        },
    },
});

interface LoginFormData {
    email: string;
    password: string;
}

interface LoginComponentProps {
    callback: (data: LoginFormData) => void;
}

export function checkIfUserIsLoggedIn() {
    const token = Cookies.get('token');
    return !!token;
}

function LoginComponent(props: LoginComponentProps) {
    const navigate = useNavigate();
    const classes = useStyles();
    const [formData, setFormData] = useState({ email: '', password: '' } as LoginFormData);
    const [show, setShow] = useState(false);
    const toast = useToast();

    const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            await props.callback(formData);
            navigate('/');
        } catch (error) {
            toast({
                title: "Login failed",
                description: "Incorrect email or password.",
                status: "error",
                duration: 9000,
                isClosable: true,
                position: "top",
            });
        }
    };

    const handleChange = (e: { target: { name: string; value: string } }) => {
        const { name, value } = e.target;

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleClick = () => {
        setShow(!show);
    };

    return (
        <Box
            maxW="400px"
            p="40px"
            borderRadius="lg"
            textAlign="center"
            bgColor="rgba(0, 0, 0, 0.25)"
            color="white"
            boxShadow="0 4px 6px -1px rgba(0, 0, 0, 0.3), 0 2px 4px -1px rgba(0, 0, 0, 0.2)"
            backdropFilter="blur(12px)"
            border="2px solid rgb(255, 255, 255)"
            position="relative"
            _before={{
                content: `""`,
                position: "absolute",
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                bgGradient: 'linear(to top, rgba(108, 52, 131, 0.2), rgba(0, 172, 237, 0))',
                backdropFilter: "blur(12px)",
                zIndex: -1,
            }}

        >
            <Text
                fontSize={["3xl", "4xl", "5xl"]}
                fontWeight="extrabold"
                color="black"
                zIndex="1"
            >
                Bienvenue
            </Text>
            <Text
                fontSize={["4xl", "5xl", "6xl"]}
                fontWeight="extrabold"
                color="black"
                mb="4"
                zIndex="1"
            >
                HML GROUPE
            </Text>
            <Text color="navy.700" fontSize="36px" mb="10px" fontWeight="500">
                Sign In
            </Text>
            <Text color="gray.400" fontSize="md" fontWeight="400" mb="36px">
                Enter your email and password to sign in!
            </Text>
            <form onSubmit={handleLogin}>
                <FormControl>
                    <FormLabel color="navy.700" fontSize="sm" fontWeight="500" mb="8px" display="flex"
                               position="relative"
                               transition="transform 0.3s, font-size 0.3s"
                               _hover={{
                                   transform: 'scale(1.1)',
                                   _after: {
                                       width: '100%'
                                   }
                               }}
                               _after={{
                                   content: '""',
                                   position: 'absolute',
                                   width: '0',
                                   height: '1px',
                                   bottom: '-7px',
                                   left: '0',
                                   backgroundColor: 'black',
                                   transition: 'width 0.420s ease-in-out',
                               }}>
                        Email
                    </FormLabel>
                    <Input marginTop={3}
                           isRequired={true}
                           fontSize="sm"
                           type="email"
                           placeholder="mail@hmlgroup.com"
                           fontWeight="500"
                           size="lg"
                           name="email"
                           value={formData.email}
                           onChange={handleChange}
                           mb="24px"
                           id="email"
                           required
                    />
                </FormControl>
                <FormControl>
                    <FormLabel color="navy.700" fontSize="sm" fontWeight="500" mb="8px" display="flex"
                               position="relative"
                               transition="transform 0.3s, font-size 0.3s"
                               _hover={{
                                   transform: 'scale(1.1)',
                                   _after: {
                                       width: '100%'
                                   }
                               }}
                               _after={{
                                   content: '""',
                                   position: 'absolute',
                                   width: '0',
                                   height: '1px',
                                   bottom: '-7px',
                                   left: '0',
                                   backgroundColor: 'black',
                                   transition: 'width 0.420s ease-in-out',
                               }}>
                        Password
                    </FormLabel>
                        <InputGroup size="lg" marginTop={3}>
                            <Input
                                isRequired={true}
                                fontSize="sm"
                                placeholder="Min. 8 characters"
                                name="password"
                                value={formData.password}
                                onChange={handleChange}
                                mb="24px"
                                type={show ? "text" : "password"}
                                id="password"
                                required
                            />
                            <InputRightElement width="4.5rem">
                                <IconButton
                                    h="1.75rem"
                                    size="sm"
                                    onClick={handleClick}
                                    icon={show ? <MdOutlineRemoveRedEye /> : <RiEyeCloseLine />}
                                    aria-label={show ? "Show password" : "Hide password"}
                                />
                            </InputRightElement>
                        </InputGroup>
                </FormControl>
                <Button
                    border-radius="50px"
                    background="#e0e0e0"
                    box-shadow=" 18px 18px 37px #bebebe,
                    -18px -18px 37px #ffffff"
                    type="submit"
                    fontSize="sm"
                    fontWeight="500"
                    bgColor="blue.500"
                    color="white"
                    _hover={{ filter: "brightness(1.2)" }}
                    h="50px"
                    w="100px"
                    onClick={() => handleLogin}
                >
                    Login
                </Button>
            </form>
        </Box>
    );
}
export default LoginComponent;
