import axios from "axios";
import {getAuthorizedHeader} from "./auth";

function getTagsColor(tag: string) {
    if (tag === 'Study') {
        return 'blue';
    } else if (tag === 'Order') {
        return 'green';
    } else if (tag === 'Construction') {
        return 'orange';
    } else if (tag === 'Site Reception') {
        return 'pink';
    } else if (tag === 'Rental') {
        return 'purple';
    } else if (tag === 'Invoicing') {
        return 'teal';
    }
    return 'red';
}

async function deleteFileInTask(taskType: string, taskId: string, fileType: string, fileId: string): Promise<void> {
    try {
        const response = await axios.delete(`/api/${taskType}/${taskId}/${fileType}/delete/${fileId}`, {
            headers: getAuthorizedHeader(),
        });
        console.log('File deleted successfully:', response.data);
    } catch (error) {
        if (error.response) {
            console.error('Error response:', error.response.data);
        } else if (error.request) {
            console.error('Error request:', error.request);
        } else {
            console.error('Error:', error.message);
        }
    }
}

export {getTagsColor, deleteFileInTask};