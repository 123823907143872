import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import theme from './theme';
import App from './app/App';
import {configureAxios} from './axiosConfiguration';
import {ChakraProvider} from '@chakra-ui/react';
import * as serviceWorker from './serviceWorker';
import {pdfjs} from 'react-pdf';

declare let window: any;

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const startApp = () => {
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
  );
  configureAxios();
  root.render(
    <React.StrictMode>
      <ChakraProvider theme={theme} resetCSS>
        <App/>
      </ChakraProvider>
    </React.StrictMode>,
  );
  serviceWorker.register();

}

if (window.cordova) {
  document.addEventListener('deviceready', startApp, false);
} else {
  startApp();
}
