import React from "react";
import {Box, Heading, Text, Badge, Divider, useColorModeValue, useBreakpointValue} from "@chakra-ui/react";

interface TeamsProps {
    teams: string[];
}
function TeamsComponent(props: TeamsProps) {
    const isSmallScreen = useBreakpointValue({base: true, md: false});

    const getColor = (team: string) => {
        if (team === 'Internal')
            return 'green';
        else if (team === 'Subcontractors')
            return 'blue';
        return 'yellow';
    };


    return (
        <Box padding={5}
             bg={useColorModeValue('white', 'gray.600')}
             border-radius={4}
             rounded="md"
             boxShadow="md"
             marginLeft={0}
             height={isSmallScreen ? "100%" : "30%"}
        >
            <Heading alignItems="flex-start" fontSize='2xl' color="gray.300">Teams</Heading>
            <Divider  width="100%"/>
            {props.teams.map((team, index) => (
                <Badge key={index} colorScheme={getColor(team)} mr={2}>
                    {team}
                </Badge>
            ))}
        </Box>
    )
}

export default TeamsComponent;