import React, {useState} from "react";
import {Box, Button, Flex, Text, VStack} from "@chakra-ui/react";
import LoginComponent from "../components/LoginComponent";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import {motion} from "framer-motion";
import {css, keyframes} from "@emotion/react";
import videoSrc from "../assets/video/video.mp4";

type LoginFormData = {
  email: string;
  password: string;
};

function LoginScreen() {
  const navigate = useNavigate();
  const MotionBox = motion(Box);
  const gradient = keyframes`
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  `;
  const animatedGradient = css`
    animation: ${gradient} 5s ease infinite;
  `;

  async function tryLogin(data: LoginFormData) {
    try {
      const response = await axios.post("/api/login", data);

      const token = response.data.token;

      Cookies.set("token", token);
      navigate("/");
    } catch (error) {
      console.error("Error logging in:", error);
      alert("Une erreur s'est produite lors de la connexion. Veuillez réessayer.");
    }
  }

  return (
      <Flex
          direction="column"
          align="center"
          justify="center"
          height="100vh"
          width="100vw"
          position="relative"
          overflow="hidden"
      >
          <video
              autoPlay
              loop
              muted
              playsInline
              style={{
                  position: "fixed",
                  width: "100vw",
                  height: "100vh",
                  objectFit: "cover",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: -1,
              }}
              src={videoSrc}
          />
          <Box zIndex="1">
              <VStack spacing="4">
                  <LoginComponent callback={tryLogin}/>
              </VStack>
          </Box>
      </Flex>

  );
}

export default LoginScreen;
