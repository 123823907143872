import React, {useState} from "react";
import {
    Box,
    Divider,
    Flex,
    Heading,
    IconButton,
    Modal, ModalBody, ModalCloseButton,
    ModalContent,
    ModalOverlay, useBreakpointValue,
    useColorModeValue, useToast
} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import InputComponent from "../common/InputComponent";
import ButtonComponent from "../common/ButtonComponent";
import axios from "axios";
import {getAuthorizedHeader} from "../../common/auth";

interface ModifyPasswordProps {
    userId: string;
    isOpen: boolean;
    handleCloseModal: () => void;
}

function ModifyPasswordComponent(props: ModifyPasswordProps) {
    const isSmallScreen = useBreakpointValue({base: true, md: false});
    const {t} = useTranslation();
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const url = props.userId === "me" ? '/api/me/password' : '/api/user/password/' + props.userId;
    const toast = useToast();

    async function handleModifyPassword() {
        if (password !== confirmPassword) {
            toast({
                title: t("passwordMismatch"),
                position: "top",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        try {
            await axios.put(
                url,
                {password},
                {headers: getAuthorizedHeader()}
            );
            toast({
                title: t("passwordUpdated"),
                position: "top",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
            props.handleCloseModal();
        } catch (error) {
            console.error("Error updating password:", error);
            toast({
                title: t("errorUpdatingPassword"),
                description: error.response?.data?.message || error.message,
                position: "top",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    }

    return (
        <Modal isOpen={props.isOpen}
               onClose={props.handleCloseModal}
               size={isSmallScreen ? "auto" : "2xl"}
        >
            <ModalOverlay bg='none'
                          backdropFilter='auto'
                          backdropBlur='2px'/>
            <ModalContent
            >
                <Flex ml={6} justifyContent="space-between" w="100%" flexDirection={{base: 'column', md: 'row'}}>
                    <Heading
                        size={"xl"}
                        color={useColorModeValue('gray.300', 'gray.400')}
                        lineHeight="tall"
                        marginBottom={isSmallScreen ? 3 : 4}
                        position="relative"
                        transition="transform 0.3s, font-size 0.3s"
                        _hover={{
                            transform: 'scale(1.1)',
                            _after: {
                                width: isSmallScreen ? '95%' : '100%',
                            }
                        }}
                        _after={{
                            content: '""',
                            position: 'absolute',
                            width: '0',
                            height: '1px',
                            bottom: '-5px',
                            left: isSmallScreen ? '2' : '0',
                            backgroundColor: 'black',
                            transition: 'width 0.420s ease-in-out',
                        }}>
                        {t("modifyPassword")}
                    </Heading>
                </Flex>
                <Divider color="gray.300" width="100%"/>
                <ModalCloseButton
                    position="absolute"
                    top="1rem"
                    right="1rem"
                    bg="transparent"
                    _hover={{bg: 'transparent'}}
                    _active={{bg: 'transparent'}}
                    sx={{
                        '& svg': {
                            transition: 'all 0.2s ease-in-out',
                        },
                        '& svg path': {
                            stroke: 'white',
                            transition: 'stroke 0.3s ease-in-out',
                        },
                        '&:hover svg path': {
                            stroke: 'red',
                            transition: 'stroke 0.5s ease-in-out 0s',
                        },
                    }}/>
                <ModalBody padding={isSmallScreen ? 3 : 5}>
                    <Box>
                        <InputComponent label={t("password")} name={'password'} type={'password'}
                                        onChange={(event) => setPassword(event.target.value)} isRequired/>
                        <InputComponent label={t("confirmPassword")} name={'confirmPassword'} type={'password'}
                                        onChange={(event) => setConfirmPassword(event.target.value)} isRequired/>
                        <ButtonComponent text={t("validate")} callback={handleModifyPassword}/>
                    </Box>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default ModifyPasswordComponent;