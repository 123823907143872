import React, {useEffect, useState} from "react";
import {
    Card,
    CardBody,
    Divider,
    Flex,
    Heading,
    Stack,
    StackDivider,
    Text,
    useBreakpointValue,
    useColorModeValue
} from "@chakra-ui/react";
import axios from "axios";
import {getAuthorizedHeader} from "../../../common/auth";
import DataDetails from "./DataDetailsComponent";
import {useTranslation} from "react-i18next";


interface ReferentProps {
    idReferent: string;
}

interface ReferentData {
    name: string;
    email: string;
    phone: string;
    role: string;
}

function ReferentComponent(props: ReferentProps) {
    const isSmallScreen = useBreakpointValue({base: true, md: false});
    const {t} = useTranslation();
    const [referent, setReferent] = useState<ReferentData | null>(null);
    const [isLoading, setIsLoading] = useState(true);

    const fetchReferent = async (idReferent: string): Promise<void> => {
        setIsLoading(true);
        try {
            const response = await axios.get('/api/referent/' + idReferent, {
                headers: getAuthorizedHeader(),
            });
            setReferent(response.data);
        } catch (error) {
            console.error('Error fetching Referents:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchReferent(props.idReferent);
    }, [props.idReferent]);

    if (isLoading) {
        return <div>{t("Loading")}</div>;
    }

    if (!referent) {
        return <div>{t("NotData")}</div>;
    }

    return (
        <Card padding={5}
              bg={useColorModeValue('white', 'gray.600')}
              border-radius={4}
              rounded="md"
              boxShadow="md"
              width={isSmallScreen ? "100%" : 'calc(50% - 10px)'}
              marginTop={isSmallScreen ? 0 : 5}
              mx={isSmallScreen ? 0 : 5}
              mb={0}
              height={"100%"}
        >
            <Heading alignItems="flex-start" fontSize='2xl' color="gray.300">{t("Referent")}</Heading>
            <Divider color="gray.300" width="100%"/>
            <DataDetails header={t("Nom")} detail={referent.name}/>
            <DataDetails header={t("Email")} detail={referent.email}/>
            <DataDetails header={t("Phone")} detail={referent.phone}/>
            <DataDetails header={t("Role")} detail={referent.role}/>
        </Card>
    );
}

export default ReferentComponent;