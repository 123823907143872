import React from 'react';
import NavBar from '../components/navigation/NavBarComponent';
import {Flex, Box, useBreakpointValue} from '@chakra-ui/react';
import UsineComponent from "../components/Usine/UsineComponent";
import StockComponent from "../components/Usine/StookComponent";
import OrderForm from "../components/Usine/OrderForm";

function UsineScreen() {
    const isSmallScreen = useBreakpointValue({base: true, md: false});

    return (
        <Box maxHeight={window.innerHeight}>
            <NavBar/>
            <Flex flexDirection={isSmallScreen ? "column" : "row"} width={"100%"} height={"100%"} padding={isSmallScreen ? 3 : 10}>
                <UsineComponent/>
                <Flex flexDirection="column" marginLeft={2}
                      width={isSmallScreen ? "100%" : "50%"}
                >
                    <StockComponent
                        currentStock={8000}
                        metalUsedThisWeek={5000}
                        metalArrivingThisWeek={7000}
                    />
                    <Box mt={4}>
                        <OrderForm />
                    </Box>
                </Flex>
            </Flex>
        </Box>
    );
}

export default UsineScreen;
