import React from "react";
import {Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, useBreakpointValue} from "@chakra-ui/react";
import TaskDetailsComponent from "./TaskDetailsComponent";

interface TaskModalProps {
    isOpen: boolean;
    handleCloseModal: () => void;
    handleCallback: () => void;
    idProject: string;
    idTask: string;
    task: string;
    status: string;
}

function TaskModalComponent({isOpen, handleCloseModal, handleCallback, idProject, idTask, task, status} : TaskModalProps) {
    const isSmallScreen = useBreakpointValue({base: true, md: false});

    return (
        <Modal isOpen={!!isOpen}
               onClose={handleCloseModal}
               size={isSmallScreen ? "auto" : "3xl"}
        >
            <ModalOverlay bg='none'
                          backdropFilter='auto'
                          backdropBlur='2px'/>
            <ModalContent
                padding={isSmallScreen ? 3 : 5}
            >
                <ModalCloseButton
                    position="absolute"
                    top="2rem"
                    right="3rem"
                    bg="transparent"
                    _hover={{bg: 'transparent'}}
                    _active={{bg: 'transparent'}}
                    sx={{
                        '& svg': {
                            transition: 'all 0.2s ease-in-out',
                        },
                        '& svg path': {
                            stroke: 'white',
                            transition: 'stroke 0.3s ease-in-out',
                        },
                        '&:hover svg path': {
                            stroke: 'red',
                            transition: 'stroke 0.5s ease-in-out 0s',
                        },
                    }}/>
                <ModalBody p={1}>
                    <TaskDetailsComponent idProject={idProject} idTask={idTask} task={task}
                                          status={status}
                                          callback={() => handleCallback()}
                                          data={true}
                    />
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default TaskModalComponent;